import React from "react";
import "./App.css";
import { Provider } from "react-redux";
import store from "./store";
import SecureRoutes from "./SecureRoutes";

import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/boxicons-2.0.7/css/boxicons.min.css";
import "./assets/css/grid.css";
import "./assets/css/theme.css";
import "./assets/css/index.css";
import CacheBuster from "react-cache-buster";

function App() {
  return (
    <CacheBuster
      currentVersion={"1.0"}
      isEnabled={true} //If false, the library is disabled.
      isVerboseMode={false} //If true, the library writes verbose logs to console.
      loadingComponent={<></>} //If not pass, nothing appears at the time of new version check.
      metaFileDirectory={"."} //If public assets are hosted somewhere other than root on your server.
    >
      <Provider store={store}>
        <SecureRoutes />
      </Provider>
    </CacheBuster>
  );
}

export default App;
