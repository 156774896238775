export const localTime = (time) => {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const now = new Date();
  const date = new Date(time);
  date.setTime(date.getTime());

  const diffSec = now.getTime() - date.getTime();
  const oneDay = 24 * 60 * 60 * 1000;
  const oneHour = 60 * 60 * 1000;
  const oneMinute = 60 * 1000;
  var month = date.getMonth() + 1;
  var day = date.getDate();
  var year = date.getFullYear();
  const result =
    diffSec > 7 * oneDay
      ? `${year}년 ${month}월 ${day}일`
      : diffSec > oneDay
        ? `${Math.trunc(diffSec / oneDay)}일 전`
        : diffSec > oneHour
          ? `${Math.trunc(diffSec / oneHour)}시간 전`
          : `${Math.trunc(diffSec / oneMinute)}분 전`;

  return result;
};
