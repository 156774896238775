export const setPost = (post) => {
  return {
    type: "SET_POST",
    payload: post,
  };
};

export const deletePost = () => {
  return {
    type: "DELETE_POST",
  };
};
export const setSchedule = (list) => {
  return {
    type: "SET_SCHEDULE",
    payload: list,
  };
};

export const setDates = (dates) => {
  return {
    type: "SET_DATES",
    payload: dates,
  };
};

export const setLang = (lang) => {
  return {
    type: "SET_LANG",
    payload: lang,
  };
};

export const setProfile = (profile) => {
  return {
    type: "SET_PROFILE",
    payload: profile,
  };
};

export const setProfiles = (profiles) => {
  return {
    type: "SET_PROFILES",
    payload: profiles,
  };
};

export const setCheckedItems = (checkedItems) => {
  return {
    type: "SET_CHECK",
    payload: checkedItems,
  };
};
