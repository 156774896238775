import Chart from "react-apexcharts";
import React from "react";
import { useSelector } from "react-redux";
import { dateString2 } from "../../datepick/dateString";
import { de } from "date-fns/locale";
import { siteKorMap } from "../../../config";

const ZoomOptions = (small_size) => ({
  options: {
    chart: {
      toolbar: {
        show: false,
      },
      background: "transparent",
      type: "line",
      stacked: false,
      height: 350,
      zoom: {
        type: "x",
        enabled: false,
        autoScaleYaxis: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    colors: ["#993BF6", "#5443FF", "#3178FF", "#3AEBFF", "#38FFA9", "#ADFF31"],
    markers: {
      size: 2,
    },
    stroke: {
      curve: "straight",
    },
    yaxis: {
      labels: {
        style: {
          fontSize: small_size ? "14px" : "16px",
        },
      },
    },

    legend: {
      position: "top",
      horizontalAlign: "left",
      fontSize: small_size ? "14px" : "14px",
      markers: {
        width: small_size ? 16 : 21,
        height: small_size ? 16 : 21,
        radius: small_size ? 4 : 5,
        offsetX: -5,
      },
      itemMargin: {
        horizontal: small_size ? 15 : 20,
      },
    },
  },
});
const compareDate = (timestamp1, start, end) => {
  const date1 = new Date(timestamp1);
  const date2 = new Date(start);

  return (
    date1.getDate() === date2.getDate() && date1.getMonth() === date2.getMonth()
  );
};
const ZoomChart = ({ series, resize }) => {
  const themeReducer = useSelector((state) => state.ThemeReducer.mode);
  const schedule = useSelector((state) => state.Others.schedule);
  let targetData = series.data.map((el) => {
    return {
      name: siteKorMap[el.name],
      data: el.data,
    };
  });
  return (
    <Chart
      options={{
        ...ZoomOptions(resize < 1280).options,
        theme: { mode: themeReducer === "theme-mode-dark" ? "dark" : "light" },
        tooltip: {
          enabled: true,
          x: {
            formatter: (val) => {
              let string = `<div>${series.category[val - 1]}</div>`;
              schedule.map((item) => {
                if (
                  item.start >= series.range[val - 1][0] &&
                  item.start <= series.range[val - 1][1]
                ) {
                  string = string + `<div>${item.title}</div>`;
                }
              });
              return string;
            },
          },
        },
        xaxis: {
          categories: series.category,
          tickAmount: 7,
          type: "category",
          labels: {
            style: {
              fontSize: resize < 1280 ? "14px" : "16px",
            },
          },
        },
        // annotations: {
        //   xaxis: schedule.map((item) =>
        //     compareDate(item.start, item.end)
        //       ? {
        //           x: item.start,
        //           strokeDashArray: 0,
        //           borderColor: "#775DD0",
        //           label: {
        //             borderColor: "#775DD0",
        //             style: {
        //               color: "#fff",
        //               background: "#775DD0",
        //             },
        //             text: item.title,
        //           },
        //         }
        //       : {
        //           x: item.start,
        //           x2: item.end,
        //           fillColor: "#775DD0",
        //           opacity: 0.4,
        //           label: {
        //             borderColor: "#775DD0",
        //             style: {
        //               color: "#fff",
        //               background: "#775DD0",
        //             },
        //             text: item.title,
        //           },
        //         }
        //   ),
        // },
      }}
      data={[]}
      series={targetData}
      type="line"
      height="350"
    />
  );
};

export default ZoomChart;
