import React from "react";
import LoadFrame from "../loading/LoadFrame";
import "./status-card.css";
import "./statuscard.css";
import { ErrorBoundary } from "react-error-boundary";
import ApiErrorFrame from "../error/ApiErrorFrame";

const StatusCard = ({ title, value, diff_value, is_loading, error}) => {
  return (
    <div className="status-card status">
      <div className="status-card__info">
        <span>{title}</span>
        <ErrorBoundary FallbackComponent={ApiErrorFrame}>
          <LoadFrame loading={is_loading} error={error}>
            {value ? (
              <>
                <h4>{value}</h4>
                <span className="detail">
                  동 기간 대비
                  {diff_value < 0 ? (
                    <span className="dsc">
                      {String(diff_value.toFixed(2)) + "%"}
                      <i className="material-icons">south_east</i>
                    </span>
                  ) : (
                    <span className="asc">
                      {"+" + String(diff_value.toFixed(2)) + "%"}
                      <i className="material-icons">north_east</i>
                    </span>
                  )}
                </span>
              </>
            ) : null}
          </LoadFrame>
        </ErrorBoundary>
      </div>
    </div>
  );
};

export default StatusCard;
