import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import Dummy from "../../assets/JsonData/dummy/profiles.json";
import { Routes, Route, BrowserRouter } from "react-router-dom";

import "../user/user.css";

import AdminRoute from "./adminRoute";
import Profile from "./profile";

const AdminPage = () => {
  const profileState = useSelector((state) => state.Others.profile);
  useEffect(() => {}, [profileState]);

  return profileState?.id ? (
    <Routes>
      <Route path="*" element={<AdminRoute />} />
    </Routes>
  ) : (
    <Profile />
  );
};

export default AdminPage;
