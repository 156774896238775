import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import "./user.css";
import { useDispatch } from "react-redux";

import chartdata from "../../assets/JsonData/dummy/chart_data1.json";
import ZoomChart from "../../components/chart/main/zoomGraph";
import BarChart2 from "../../components/chart/main/barGraph2";
import DonutChart from "../../components/chart/main/donut";
import dummyMain from "../../assets/JsonData/dummy/mainpage.json";
import useApi from "../../components/hooks/useApi";
import {
  getSocialMainChart,
  getSocialMainData,
} from "../../redux/actions/api_user";

import SlideCard from "../../components/status-card/SlideCard";
import DateRange from "../../components/datepick/dateRange";
import LoadFrame from "../../components/loading/LoadFrame";
import StatusCard from "../../components/status-card/StatusCard";
import ApiErrorFrame from "../../components/error/ApiErrorFrame";
import { ErrorBoundary } from "react-error-boundary";

const SocialMainPage = () => {
  const originDates = useSelector((state) => state.Others.dates);
  const [chartLoading, chartData, error1, getChart] =
    useApi(getSocialMainChart);
  const [subLoading, subData, error2, getSub] = useApi(getSocialMainData);
  const [dataToRender, setData] = useState({});
  const [chartToRender, setChart] = useState({});

  const [resize, setResize] = useState();
  const profile = useSelector((state) => state.Others.profile);
  const lang = useSelector((state) => state.Others.lang);

  const handleResize = () => {
    setResize(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (!profile.dummy) {
      getChart(originDates.type, lang);
      getSub(originDates.type, lang);
    }
  }, [originDates, profile, lang]);

  useEffect(() => {
    if (error1) {
      setData(chartdata);
    } else if (chartData) {
      setChart(chartData.data);
    }
  }, [chartData, error1]);

  useEffect(() => {
    if (error2) {
      setData(dummyMain);
    } else if (subData) {
      setData(subData.data);
    }
  }, [subData, error2]);

  return (
    <div>
      <div className="page-header">
        <span>Leaderboard</span>
      </div>

      <div className="page-body">
        {/* <div>
          <div className="range-select-button margin-btm-0">
            <button className="small-button" onClick={()=>{dispatch(setLang('en'))}}>
              EN
            </button>
            <button className="small-button" onClick={()=>{dispatch(setLang('kr'))}}>
              KR
            </button>
          </div>
        </div> */}
        <div>
          <DateRange usePickModal={false} />
        </div>
        <div>
          <div className="d-row">
            <div className="col-3 left">
              <StatusCard
                title="전체 댓글 수"
                value={dataToRender.counts?.total}
                diff_value={dataToRender.allstatuscard?.total_comments_diff}
                error={error1}
              />
            </div>
            <div className="col-3">
              <StatusCard
                title="긍정 댓글 수"
                value={dataToRender.counts?.positive}
                diff_value={dataToRender.allstatuscard?.positive_comments_diff}
                error={error1}
              />
            </div>
            <div className="col-3">
              <StatusCard
                title="중립 댓글 수"
                value={dataToRender.counts?.neutral}
                diff_value={dataToRender.allstatuscard?.neutral_comments_diff}
                error={error1}
              />
            </div>
            <div className="col-3 right">
              <StatusCard
                title="부정 댓글 수"
                value={dataToRender.counts?.negative}
                diff_value={dataToRender.allstatuscard?.negative_comments_diff}
                error={error1}
              />
            </div>
          </div>
        </div>
        <div>
          <div className="card full-height">
            <div className="card__header">
              <h3>언급 수 변화</h3>
            </div>
            <ErrorBoundary FallbackComponent={ApiErrorFrame}>
              <LoadFrame loading={chartLoading || !chartToRender.range} error={error2}>
                {chartToRender.range ? (
                  <ZoomChart series={chartToRender} resize={resize} />
                ) : null}
              </LoadFrame>
            </ErrorBoundary>
          </div>
        </div>
        <div>
          <div>
            <div className="card social-contents">
              <div className="card__header">
                <h3>커뮤니티 별 대표 게시글</h3>
              </div>
              <ErrorBoundary FallbackComponent={ApiErrorFrame}>
                <LoadFrame loading={subLoading || !dataToRender.contents} error={error1}>
                  {dataToRender.contents ? (
                    <SlideCard
                      data={dataToRender.contents}
                      resize={resize}
                      columnNames={["플랫폼", "댓글 수", "감성 분석"]}
                      social={true}
                    />
                  ) : null}
                </LoadFrame>
              </ErrorBoundary>
            </div>
          </div>
        </div>
        <div className="social-row">
          <div className="d-row">
            <div className="col-6 left">
              <div className="hcomments-pie-container">
                <div className="card full-height">
                  <div className="card__header">
                    <h3>커뮤니티별 언급 비율</h3>
                  </div>
                  <div className="card__body">
                    <ErrorBoundary FallbackComponent={ApiErrorFrame}>
                      <LoadFrame loading={subLoading || !dataToRender.community} error={error1}>
                        {dataToRender.community ? (
                          <DonutChart
                            series={Object.values(dataToRender.community)}
                            labels={Object.keys(dataToRender.community)}
                            resize={resize}
                          />
                        ) : null}
                      </LoadFrame>
                    </ErrorBoundary>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6 right scomments-barchart-container">
              <div className="card full-height">
                {/* chart */}
                <div className="card__header">
                  <h3>기간별 긍/부정 추이</h3>
                </div>
                <div className="card__body">
                  <ErrorBoundary FallbackComponent={ApiErrorFrame}>
                    <LoadFrame loading={subLoading || !dataToRender.periodData} error={error1}>
                      {dataToRender.periodData && dataToRender.periodData[0] ? (
                        <BarChart2
                          series={dataToRender.periodData}
                          resize={resize}
                        />
                      ) : null}
                    </LoadFrame>
                  </ErrorBoundary>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SocialMainPage;
