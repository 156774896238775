import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import { useDispatch } from "react-redux";

const useApiSimple = (api, setInitLoadingValue = false) => {
  const [loading, setLoading] = useState(setInitLoadingValue);
  const dispatch = useDispatch();

  const callApi = async (...args) => {
    setLoading(true);
    await dispatch(api(...args));
    setLoading(false);
  };
  return [loading, callApi];
};

export default useApiSimple;
