import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import Dummy from "../../assets/JsonData/dummy/profiles.json";
import { Routes, Route } from "react-router-dom";
import UserRoute from "./userRoute";

import "./user.css";

import useApi from "../../components/hooks/useApi";
import { getClients } from "../../redux/actions/api_user";
import { useDispatch } from "react-redux";
import { setProfile, setProfiles } from "../../redux/actions/others";

const HomePage = () => {
  const profileState = useSelector((state) => state.Others.profile);
  useEffect(() => {}, [profileState]);

  const dispatch = useDispatch();
  const [clientsLoading, clients, error, getClientsAction] = useApi(getClients);
  const [dataToRender, setData] = useState({});
  useEffect(() => {
    getClientsAction();
  }, []);
  useEffect(() => {
    if (clients && !error) {
      setData(clients?.data);
    } else if (error) {
      setData(Dummy);
    }
  }, [clients, error]);
  useEffect(() => {
    dispatch(setProfiles(dataToRender));
    if (dataToRender.length && dataToRender[0]) {
      let profile_data = dataToRender[0];
      dispatch(setProfile(profile_data));
    }
  }, [dataToRender]);

  return profileState?.id ? (
    <Routes>
      <Route path="*" element={<UserRoute />} />
    </Routes>
  ) : null;
  // <Profile />
};

export default HomePage;
