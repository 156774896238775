import React, { useState } from "react";
import { Users } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { setHover, setClick } from "../../redux/actions/keyword";

import "./table.css";

const RankTable = (props) => {
  const hover = useSelector((state) => state.HoverReducer.hover);
  const click = useSelector((state) => state.HoverReducer.click);
  const dispatch = useDispatch();

  useState(() => {}, [hover]);
  return (
    <div className="table-rank">
      <table>
        {props.bodyData?.length ? (
          <tbody>
            {props.bodyData.map((item, index) => (
              <tr
                className={
                  (item.text === hover ? "hovered" : "") +
                  " " +
                  (item.text === click ? "clicked" : "")
                }
                onClick={() => dispatch(setClick(item.text))}
                onMouseEnter={() => dispatch(setHover(item.text))}
                onMouseLeave={() => dispatch(setHover(""))}
                key={index}
              >
                <td className="num">{index + 1}</td>
                <td className="key">{item.text}</td>
              </tr>
            ))}
          </tbody>
        ) : (
          <div style={{ fontSize: "0.5em", marginLeft: "10px" }}>
            분석된 데이터가 없습니다 <p />
            <span
              className="material-icons-outlined"
              data-toggle="popover"
              title="분석에 필요한 데이터가 부족할 수 있습니다."
            >
              info
            </span>
          </div>
        )}
      </table>
    </div>
  );
};

export default RankTable;
