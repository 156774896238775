const HoverReducer = (state = { hover: "", click: "" }, action) => {
  switch (action.type) {
    case "SET_HOVER":
      return {
        ...state,
        hover: action.payload,
      };
    case "SET_CLICK":
      return {
        ...state,
        click: action.payload,
      };
    default:
      return state;
  }
};

export default HoverReducer;
