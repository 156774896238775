import React from "react";
import "./mail.css";
import { useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { deletePost } from "../../redux/actions/others";

import Badge from "@mui/material/Badge";
import { siteImage } from "../site/siteImage";
import { localTime } from "../../function";

const MailPostModal = () => {
  const others = useSelector((state) => state.Others);
  const dispatch = useDispatch();
  return (
    <Modal
      className="modal"
      show={others.modal}
      onHide={() => dispatch(deletePost())}
    >
      <Modal.Header closeButton>
        <div className="d-row">
          <div className="col-3">
            <Badge
              badgeContent={others.post?.board_dc_type === "M" ? "M" : 0}
              color="primary"
            >
              <img
                src={siteImage(others.post.site)}
                className="logo"
                alt="site"
              />
            </Badge>
          </div>
          <div className="col-9 description">
            {others.post?.board_description}
          </div>
          <div>
            <div className="title">{others.post.title}</div>
            <div className="nickname">{others.post.nickname}</div>
            <div className="site">{others.post.site}</div>
          </div>

          <div className="date">
            {localTime(others.post.timestamp || others.post.written_at)}
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="modal__content">
          <div className="content">
            {others.post?.content && <div>{others.post.content}</div>}
            {others.post?.type === "comment" && (
              <div className="comment-parent">
                <div className="parent">원 게시글 내용</div>
                {others.post?.hate_post_title && (
                  <div className="parent-title">
                    {others.post.hate_post_title}
                  </div>
                )}
                {others.post?.hate_post_content && (
                  <div className="parent-content">
                    {others.post.hate_post_content}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <p className="category">
          {others.post.category || others.post.tag_name}
        </p>
        <a href={others.post.url} target="_blank" rel="noreferrer">
          원게시글 링크
        </a>
        {others.post?.has_comment_url && (
          <a href={others.post.comment_url} target="_blank" rel="noreferrer">
            원댓글 링크
          </a>
        )}
        <a href={others.post.screenshot} target="_blank" rel="noreferrer">
          스크린샷
        </a>
      </Modal.Footer>
    </Modal>
  );
};

export default MailPostModal;
