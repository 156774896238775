import React, { useEffect, useRef, useState } from "react";
import { contentStatus, siteKorMap } from "../../config";

import "./platform-table.css";

import { siteImage } from "../site/siteImage";

const PlatformRow = ({ item, index, maxIndex, active, handleClick }) => {
  if (index >= maxIndex) {
    return null;
  }
  return (
    <div
      key={index}
      onClick={
        !item.contents && item.hateNumber === 0
          ? null
          : () => {
              handleClick(index);
            }
      }
      onKeyDown={
        !item.contents && item.hateNumber === 0
          ? null
          : () => {
              handleClick(index);
            }
      }
      className={
        "platform-table-row elem" +
        (active ? " active" : "") +
        (!item.contents && item.hateNumber === 0 ? " disabled" : "")
      }
      role="button"
      tabIndex={0}
    >
      <div className="platform-table-text">
        <img src={siteImage(item.site)} alt="site" />
        <span>{siteKorMap[item.site]}</span>
      </div>
      <div className="platform-table-text">{item.hateNumber}</div>
      <div className="platform-table-text with-bargraph">
        <div>{item.hateRate.toFixed(0) + "%"}</div>
        <div className="small-bargraph">
          <div style={{ width: item.hateRate + "%" }} />
        </div>
      </div>
    </div>
  );
};

const PlatformSocialRow = ({ item, index, maxIndex, active, handleClick }) => {
  if (index >= maxIndex) {
    return null;
  }
  return (
    <div
      key={index}
      onClick={
        !item.contents && item.numComments === 0
          ? null
          : () => {
              handleClick(index);
            }
      }
      onKeyDown={
        !item.contents && item.numComments === 0
          ? null
          : () => {
              handleClick(index);
            }
      }
      className={
        "platform-table-row elem" +
        (active ? " active" : "") +
        (!item.contents && item.numComments === 0 ? " disabled" : "")
      }
      role="button"
      tabIndex={0}
    >
      <div className="platform-table-text">
        <img src={siteImage(item.site)} alt="site" />
        <span>{siteKorMap[item.site]}</span>
      </div>
      <div className="platform-table-text">{item.numComments}</div>
      <div className="platform-table-text with-bargraph">
        <div>{item.positiveRate.toFixed(0) + "%"}</div>
        <div className="small-bargraph">
          {/* <div style={{ width: item.positiveRate + "%" }} /> */}
          <div
            className="pos"
            style={{ width: String(item.positiveRate.toFixed(2)) + "%" }}
          />
          <div
            className="neg"
            style={{ width: String(item.negativeRate.toFixed(2)) + "%" }}
          />
        </div>
      </div>
    </div>
  );
};

const PlatformHeadContent = ({ item, index }) => (
  <div key={index} className="platform-table-head-content">
    {item}
  </div>
);

const PlatformTable = (props) => {
  const initDataShow =
    props.limit && props.bodyData
      ? props.bodyData.slice(0, Number(props.limit))
      : props.bodyData;

  const [dataShow, setDataShow] = useState(initDataShow);

  useEffect(() => {
    if (props.bodyData) {
      setDataShow(props.bodyData);
    }
  }, [props.bodyData]);

  const ref = useRef(null);

  const [divHeight, setDivHeight] = useState();

  useEffect(() => {
    setDivHeight(ref?.current?.clientHeight);
  }, [props.resize]);

  const generateRows = (height) => {
    let division_height = props.resize < 1280 ? 57 : 72;
    // const maxIndex = parseInt(height / division_height) - 1;
    const maxIndex = 5;
    return dataShow.map((item, index) =>
      props.social ? (
        <PlatformSocialRow
          item={item}
          index={index}
          maxIndex={maxIndex}
          active={index === props.currentIndex}
          handleClick={props.setCurrentIndex}
          key={index}
        />
      ) : (
        <PlatformRow
          item={item}
          index={index}
          maxIndex={maxIndex}
          active={index === props.currentIndex}
          handleClick={props.setCurrentIndex}
          key={index}
        />
      ),
    );
  };

  return (
    <div className="table platform-table">
      <div className="table-wrapper" ref={ref}>
        {props.headData && (
          <div className="platform-table-row platform-table-head">
            {props.headData.map((item, index) => (
              <PlatformHeadContent item={item} index={index} key={index} />
            ))}
          </div>
        )}
        {props.bodyData && (
          <div className="platform-table-body">{generateRows(divHeight)}</div>
        )}
      </div>
    </div>
  );
};

export default PlatformTable;
