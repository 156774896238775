export const siteImage = (site) => {
  switch (site) {
    case "dcinside":
      return "/logo/dcinside.png";
    case "youtube":
      return "/logo/youtube.png";
    case "nate_pann":
      return "/logo/natepan.png";
    case "melon":
      return "/logo/melon.png";
    case "ilbe":
      return "/logo/ilbe.png";
    case "fmkorea":
      return "/logo/fmkorea.png";
    case "theqoo":
      return "/logo/theqoo.png";
    case "mlbpark":
      return "/logo/mlbpark.jpeg";
    case "instiz":
      return "/logo/instiz.webp";
    case "instagram":
      return "/logo/instagram.png";
    case "naver_news":
    case "naver_cafe":
    case "naver_blog":
      return "/logo/naver.webp";
    case "hiphople":
      return "/logo/hiphople.png";
    case "ssul":
      return "/logo/ssul.png";
    case "issuya":
      return "/logo/issuya.png";
    case "ygosu":
      return "/logo/ygosu.jpg";
    case "reddit":
      return "/logo/reddit.jpg";
    default:
      return "/logo/default.png";
  }
};

export const siteColor = (site) => {
  switch (site) {
    case "dcinside":
      return "#2e4399";
    case "youtube":
      return "red";
    case "nate_pann":
      return "red";
    case "ilbe":
      return "red";
    case "fmkorea":
      return "#2e4399";
    case "theqoo":
      return "#214e77";
    case "mlbpark":
      return "orange";
    case "instiz":
      return "#55ed65";
    case "instagram":
      return "#d13d9d";
    case "naver_news":
    case "naver_cafe":
    case "naver_blog":
      return "#55ed65";
    case "melon":
      return "#55ed65";
    default:
      return "black";
  }
};
