const dateString = (date) => {
  return (
    String(date.getFullYear()) +
    "-" +
    String(date.getMonth() + 1).padStart(2, "0") +
    "-" +
    String(date.getDate()).padStart(2, "0")
  );
};

export const dateString2 = (time) => {
  const date = new Date(time);
  return (
    String(date.getMonth() + 1).padStart(2, "0") +
    "월 " +
    String(date.getDate()).padStart(2, "0") +
    "일"
  );
};

export default dateString;
