import React from "react";
import { Blocks, MagnifyingGlass, FallingLines } from "react-loader-spinner";
import { ErrorBoundary, useErrorBoundary } from 'react-error-boundary'
import "./loading.css";


export const LoadElement = ({ main }) => {
  return (
    <div className="loading-element">
      <img src="/logo/icon.png" alt="logo" className="load-img" />
      <div className="load-text">Check Login</div>
      <FallingLines
        color={main ? "#5443ff" : "#1b7afe"}
        height={"10vh"}
        width={"10vh"}
        ariaLabel="falling-lines-loading"
      />
    </div>
  );
};


const LoadFrame = ({ loading, error, children }) => {
  const {showBoundary} = useErrorBoundary()

  if (error){
    showBoundary(error);
  }
  
  return (
    <div className="LoadFrame">
      {loading ? (
        <FallingLines
          color="#5443ff"
          height={80}
          width={80}
          ariaLabel="falling-lines-loading"
        />
      ) : (
        children
      )}
    </div>
  );
};
export default LoadFrame;
