import dummyData from "../../../assets/JsonData/dummy/mainpage.json";
import Chart from "react-apexcharts";
import { useSelector } from "react-redux";
import React from "react";
import "./main_chart.css";
import {
  BiNews,
  BiGroup,
  BiMusic,
  BiMessageAltDetail,
  BiBorderAll,
} from "react-icons/bi";
import { siteKorMap } from "../../../config";

const categoryIcon = (category) => {
  switch (category) {
    case "뉴스":
      return `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-newspaper" viewBox="0 0 16 16">
      <path d="M0 2.5A1.5 1.5 0 0 1 1.5 1h11A1.5 1.5 0 0 1 14 2.5v10.528c0 .3-.05.654-.238.972h.738a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 1 1 0v9a1.5 1.5 0 0 1-1.5 1.5H1.497A1.497 1.497 0 0 1 0 13.5v-11zM12 14c.37 0 .654-.211.853-.441.092-.106.147-.279.147-.531V2.5a.5.5 0 0 0-.5-.5h-11a.5.5 0 0 0-.5.5v11c0 .278.223.5.497.5H12z"/>
      <path d="M2 3h10v2H2V3zm0 3h4v3H2V6zm0 4h4v1H2v-1zm0 2h4v1H2v-1zm5-6h2v1H7V6zm3 0h2v1h-2V6zM7 8h2v1H7V8zm3 0h2v1h-2V8zm-3 2h2v1H7v-1zm3 0h2v1h-2v-1zm-3 2h2v1H7v-1zm3 0h2v1h-2v-1z"/>
    </svg>`;
    case "커뮤니티":
      return `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-people-fill" viewBox="0 0 16 16">
      <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
      <path fill-rule="evenodd" d="M5.216 14A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216z"/>
      <path d="M4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z"/>
    </svg>`;
    case "SNS":
      return `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chat-dots" viewBox="0 0 16 16">
      <path d="M5 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
      <path d="m2.165 15.803.02-.004c1.83-.363 2.948-.842 3.468-1.105A9.06 9.06 0 0 0 8 15c4.418 0 8-3.134 8-7s-3.582-7-8-7-8 3.134-8 7c0 1.76.743 3.37 1.97 4.6a10.437 10.437 0 0 1-.524 2.318l-.003.011a10.722 10.722 0 0 1-.244.637c-.079.186.074.394.273.362a21.673 21.673 0 0 0 .693-.125zm.8-3.108a1 1 0 0 0-.287-.801C1.618 10.83 1 9.468 1 8c0-3.192 3.004-6 7-6s7 2.808 7 6c0 3.193-3.004 6-7 6a8.06 8.06 0 0 1-2.088-.272 1 1 0 0 0-.711.074c-.387.196-1.24.57-2.634.893a10.97 10.97 0 0 0 .398-2z"/>
    </svg>`;
    case "음악":
      return `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-music-note-beamed" viewBox="0 0 16 16">
      <path d="M6 13c0 1.105-1.12 2-2.5 2S1 14.105 1 13c0-1.104 1.12-2 2.5-2s2.5.896 2.5 2zm9-2c0 1.105-1.12 2-2.5 2s-2.5-.895-2.5-2 1.12-2 2.5-2 2.5.895 2.5 2z"/>
      <path fill-rule="evenodd" d="M14 11V2h1v9h-1zM6 3v10H5V3h1z"/>
      <path d="M5 2.905a1 1 0 0 1 .9-.995l8-.8a1 1 0 0 1 1.1.995V3L5 4V2.905z"/>
    </svg>`;
    default:
      return <BiBorderAll />;
  }
};

const DonutOptions = (small_size) => ({
  options: {
    chart: {
      background: "transparent",
      type: "donut",
      height: "auto",
    },
    labels: Object.keys(dummyData.analysis.community),
    dataLabels: {
      enabled: false,
      formatter: function (val, opt) {
        return val + "%";
      },
    },
    plotOptions: {
      pie: {
        expandOnClick: true,
      },
    },
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w, val }) {
        let total = 0;
        for (let x of series) {
          total += x;
        }
        let selected = series[seriesIndex];

        return `<div class="tooltip">
          <span>
          <span class="name">${
            w.config.legend.customLegendItems[seriesIndex]
          }</span>  <span class="number"> ${((selected / total) * 100).toFixed(2) + "%"}</span>
          
          </span>
          </div>`;
      },
    },
  },
});

const DonutChart = ({ series, labels, resize }) => {
  const themeReducer = useSelector((state) => state.ThemeReducer.mode);

  const Series = series ? series.slice(0, 4) : null;
  const Labels = labels ? labels.slice(0, 4) : null;

  let targetData = Labels.map((el) => {
    return siteKorMap[el];
  });

  return (
    <Chart
      options={{
        ...DonutOptions(resize < 1280).options,
        theme: { mode: themeReducer === "theme-mode-dark" ? "dark" : "light" },
        label: labels,
        colors: ["#3AEBFF", "#3178FF", "#912FF3", "#38FFA9"],
        legend: {
          position: "right",
          fontSize: "13em",
          customLegendItems: targetData,
          formatter: function (seriesName, opts) {
            return [
              `${seriesName}<p class="legend-text">${
                opts.w.globals.series[opts.seriesIndex]
              }</p>`,
            ];
          },
          markers: {
            width: resize < 1280 ? 15 : 20,
            height: resize < 1280 ? 15 : 20,
            radius: resize < 1280 ? 5 : 6,
            offsetX: -10,
          },
          itemMargin: {
            vertical: 5,
          },
        },
      }}
      data={[]}
      series={Series}
      type="donut"
      height={resize < 1280 ? "225" : "275"}
      width={"100%"}
    />
  );
};
export default DonutChart;
