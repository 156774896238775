import { useSelector } from "react-redux";
import React, { useEffect } from "react";
import Chart from "react-apexcharts";

const BarOptions = (small_size) => ({
  options: {
    chart: {
      background: "transparent",
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      type: "bar",
    },
    bar: {
      horizontal: false,
      columnWidth: "55%",
      endingShape: "rounded",
    },
    colors: ["#E0E5FF", "#5443ff"],
    states: {
      hover: {
        filter: {
          type: "none",
        },
      },
    },

    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 5,
        columnWidth: "40%",
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      fontSize: small_size ? "12px" : "14px",
      markers: {
        width: 24,
        height: 24,
        strokeWidth: 0,
        radius: 8,
        offsetX: 0,
        offsetY: 0,
      },
      itemMargin: {
        horizontal: 14,
        vertical: 14,
      },
    },
    yaxis: {
      labels: {
        style: {
          fontSize: small_size ? "12px" : "14px",
        },
      },
    },
  },

  stroke: {
    show: true,
    width: 2,
    colors: ["transparent"],
  },
  yaxis: {
    title: {
      text: "$ (thousands)",
    },
  },
  fill: {
    opacity: 1,
  },
  tooltip: {
    y: {
      formatter: function (val) {
        return "$ " + val + " thousands";
      },
    },
  },
});

const BarChart1 = ({ series, resize }) => {
  useEffect(() => {
    // console.log(series);
  }, [series]);
  const themeReducer = useSelector((state) => state.ThemeReducer.mode);
  return (
    <Chart
      data={[]}
      options={{
        ...BarOptions(resize < 1280).options,
        ...(themeReducer === "theme-mode-dark"
          ? { theme: { mode: "dark" } }
          : { theme: { mode: "light" } }),
        xaxis: {
          categories: series.xval,
          tickAmount: 7,
          type: "category",
          labels: {
            style: {
              fontSize: resize < 1280 ? "12px" : "14px",
            },
          },
        },
      }}
      series={[
        {
          name: "댓글",
          data: series.normal,
        },
        {
          name: "악플",
          data: series.hate,
        },
      ]}
      type="bar"
      height={resize < 1280 ? "225" : "275"}
    />
  );
};

export default BarChart1;
