import React, { useEffect, useState } from "react";

import useApi from "../../components/hooks/useApi";

import { useDispatch, useSelector } from "react-redux";
import PostTable from "../../components/table/postTable";
import RankTable from "../../components/table/rankTable";
import dummyDetail from "../../assets/JsonData/dummy/hate_keyword.json";
import dummyMain from "../../assets/JsonData/dummy/hate_main.json";
import ReactWordcloud from "react-wordcloud";
import CloudOptions from "../../components/chart/detail/wordCloud";
import DateRange from "../../components/datepick/dateRange";
import {
  getHateKeywordData,
  getHateKeywordSubData,
} from "../../redux/actions/api_user";
import { setClick } from "../../redux/actions/keyword";

import LoadFrame from "../../components/loading/LoadFrame";
import ApiErrorFrame from "../../components/error/ApiErrorFrame";
import { ErrorBoundary } from "react-error-boundary";

const HateKeywordPage = () => {
  const [loading, dataHate, error, getHate] = useApi(getHateKeywordData);
  const [loading2, subData, error2, getSub] = useApi(getHateKeywordSubData);
  const [dataToRank, setRank] = useState([]);
  const [dataToRender, setData] = useState({});
  const originDates = useSelector((state) => state.Others.dates);
  const click = useSelector((state) => state.HoverReducer.click);
  const profile = useSelector((state) => state.Others.profile);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!profile.dummy) {
      getHate(originDates.start, originDates.end, originDates.type);
      // getSub(originDates.start, originDates.end, originDates.type, click);
    }
  }, [originDates, profile]);

  useEffect(() => {
    if (error) {
      setRank(dummyMain);
    } else if (dataHate) {
      let index = 3;
      while (true) {
        if (dataHate.data[index].rank[0]) {
          dispatch(setClick(dataHate.data[index].rank[0]?.text));
          break;
        } else {
          index -= 1;
        }
        if (index < 0) {
          console.log("unable to click, check data");
          break;
        }
      }
      setRank(dataHate.data);
    }
  }, [dataHate, error]);

  useEffect(() => {
    if (click && !profile.dummy) {
      getSub(originDates.start, originDates.end, originDates.type, click);
    }
  }, [click, profile]);

  useEffect(() => {
    if (error2) {
      setData(dummyDetail);
    } else if (subData) {
      setData(subData.data);
    }
  }, [subData, error2]);

  useEffect(() => {
    return () => {
      setRank([]);
      setData({});
      dispatch(setClick(""));
    };
  }, []);

  const callbacks = {
    getWordColor: (word) =>
      word.position > 0 ? "#4CD964" : word.position < 0 ? "#EF4444" : "#757575",
  };
  return (
    <div>
      <div className="page-header">
        <span>악플 키워드 분석</span>
      </div>
      <div className="page-body">
        <div>
          <DateRange usePickModal={false} />
        </div>
        <div className="card">
          <div className="card__header">
            <h3>키워드 랭킹</h3>
          </div>
          <ErrorBoundary FallbackComponent={ApiErrorFrame}>
            <LoadFrame loading={loading && !dataToRank[0]} error={error}>
              {dataToRank[0] ? (
                <div className="rank-main">
                  <div className="rank">
                    <p className="table-title">{dataToRank[0].range}</p>
                    <RankTable bodyData={dataToRank[0].rank.slice(0, 10)} />
                  </div>
                  <div className="rank">
                    <p className="table-title">{dataToRank[1].range}</p>
                    <RankTable bodyData={dataToRank[1].rank.slice(0, 10)} />
                  </div>
                  <div className="rank">
                    <p className="table-title">{dataToRank[2].range}</p>
                    <RankTable bodyData={dataToRank[2].rank.slice(0, 10)} />
                  </div>
                  <div className="rank">
                    <p className="table-title">{dataToRank[3].range}</p>
                    <RankTable bodyData={dataToRank[3].rank.slice(0, 10)} />
                  </div>
                </div>
              ) : null}
            </LoadFrame>
          </ErrorBoundary>
        </div>
        <div className="d-row post-table-row">
          <div className="col-6 left">
            <div className="card">
              <div className="card__header">
                <h3>대표 게시글</h3>
              </div>
              <div className="card__body">
                <ErrorBoundary FallbackComponent={ApiErrorFrame}>
                  <LoadFrame loading={loading || loading2 || !dataToRender.post} error={error2}>
                    {dataToRender.post ? (
                      <PostTable limit="5" bodyData={dataToRender?.post} />
                    ) : null}
                  </LoadFrame>
                </ErrorBoundary>
              </div>
            </div>
          </div>

          <div className="col-6 right">
            <div className="card related-keyword">
              <div className="card__header">
                <h3>연관 키워드</h3>
              </div>
              <div className="card__body">
                <LoadFrame
                  loading={loading || loading2 || !dataToRender.wordcloud}
                >
                  {dataToRender.wordcloud ? (
                    <ReactWordcloud
                      options={CloudOptions}
                      words={dataToRender?.wordcloud}
                      callbacks={callbacks}
                    />
                  ) : null}
                </LoadFrame>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HateKeywordPage;
