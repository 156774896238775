import React, { useState, useEffect } from "react";
import useApiSimple from "../../../components/hooks/useApiSimple";
import { signin } from "../../../redux/actions/auth";
import "./Signin.css";
import { useSelector, useDispatch } from "react-redux";
import { Navigate } from "react-router-dom";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

const SigninPage = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState(false);
  const [loading, setLoading] = useState(true);

  const [signinLoading, signinAction] = useApiSimple(signin);

  const auth = useSelector((state) => state.auth);

  const onSubmit = (e) => {
    e.preventDefault();
    signinAction(username, password);
    return false;
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      onSubmit(e);
    }
  };

  return (
    <div className="signin">
      <div className="sign-right">
        <img src="/logo/icon3.png" alt="signin" />
        <h1>THINKPOL</h1>
        {errors === true && <h2>Cannot log in with provided credentials</h2>}
        <TextField
          label="Username"
          variant="standard"
          onChange={(e) => setUsername(e.target.value)}
          onKeyPress={handleKeyPress}
        />
        <br />
        <TextField
          label="Password"
          variant="standard"
          onChange={(e) => setPassword(e.target.value)}
          onKeyPress={handleKeyPress}
          type="password"
        />
        <br />
        <br />
        <Button
          classes="login-bt"
          size="large"
          variant="outlined"
          onClick={onSubmit}
        >
          LOGIN
        </Button>
      </div>
    </div>
  );
};

export default SigninPage;
