import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useSelector, useDispatch } from "react-redux";
import { Carousel } from "react-responsive-carousel";
import Table from "../table/PlatformTable";
import React, { useRef, useState, useEffect } from "react";
import { siteImage, siteColor } from "../site/siteImage";
import "./slide-card.css";
import RenderWrapper from "../display/renderWrapper";
import useApi from "../hooks/useApi";
import { ignorePost } from "../../redux/actions/api_user";

const contentsList = ["플랫폼", "악플 수", "악플 비율"];

const SlideCard = ({ data, resize, columnNames, social = false }) => {
  const direction = "forward";
  const duration = 10;
  const [current, setCurrent] = useState(0);
  const auth = useSelector((state) => state.auth);
  const length = data.length;
  const [loading, ignoreResult, error3, ignoreAction] = useApi(ignorePost);

  let actualLength = 0;
  data.forEach((el) => {
    if (el.contents || el.hateNumber || el.numComments) {
      actualLength += 1;
    }
  });

  useEffect(() => {
    const interval = setInterval(() => {
      if (direction === "forward") {
        setCurrent(current === actualLength - 1 ? 0 : current + 1);
      } else {
        setCurrent(current === 0 ? actualLength - 1 : current - 1);
      }
    }, duration * 1000);
    return () => clearInterval(interval);
  }, [duration, direction, current, length]);

  if (!Array.isArray(data) || data.length <= 0) {
    return null;
  }

  const handleSetCurrent = (index) => {
    if (index >= actualLength) {
      return;
    }
    setCurrent(index);
  };

  return (
    <div className="card__body">
      <section className="image-slider">
        <div className="slider">
          {data.map((content, index) => (
            <div
              key={index}
              className={index === current ? "slide active" : "slide"}
            >
              {index === current && (
                <div className="post-card">
                  <img
                    src={siteImage(content.site)}
                    className="post-img"
                    alt="postimg"
                  />

                  <h2>{content.title}</h2>
                  <p className="writer">{content.name}</p>
                  {social ? (
                    <div>댓글 수 : {content.postNumComments}</div>
                  ) : null}
                  <hr style={{ backgroundColor: siteColor(content.site) }} />
                  <p className="image-slider-content">{content.contents}</p>

                  <div className="platform-table-text with-bargraph w-60">
                    {/* <div>{content.ratio?.negative.toFixed(0) + "%"}</div>
                        <div className="small-bargraph">
                        <div style={{ width: content.ratio?.positive + "%" }} />
                    </div> */}
                    {social ? (
                      <>
                        <div>
                          감성 분석 :{" "}
                          {String(content.positiveRate.toFixed(2)) + "%"}
                        </div>
                        <div className="small-bargraph">
                          <div
                            className="pos"
                            style={{
                              width:
                                String(content.positiveRate.toFixed(2)) + "%",
                            }}
                          />
                          <div
                            className="neg"
                            style={{
                              width:
                                String(content.negativeRate.toFixed(2)) + "%",
                            }}
                          />
                        </div>
                      </>
                    ) : null}
                  </div>

                  <a
                    target="_blank"
                    style={{ borderColor: siteColor(content.site) }}
                    className="small-button link"
                    href={content.url}
                    rel="noreferrer"
                  >
                    게시글 보러가기
                  </a>
                  <RenderWrapper condition={auth.user.role === "admin"}>
                    <button
                      style={{ borderColor: siteColor(content.site) }}
                      className="small-button link"
                      onClick={() =>
                        ignoreAction({
                          site_id: content.site_id,
                          board_id: content.board_id,
                          post_no: content.post_no,
                        })
                      }
                    >
                      삭제
                    </button>
                  </RenderWrapper>
                </div>
              )}
            </div>
          ))}
        </div>
      </section>
      <Table
        headData={columnNames || contentsList}
        // renderHead={(item, index) => renderOrderHead(item, index)}
        bodyData={data}
        // renderBody={(item, index) => renderOrderBody(item, index)}
        currentIndex={current}
        setCurrentIndex={handleSetCurrent}
        resize={resize}
        social={social}
      />
    </div>
  );
};

export default SlideCard;
