import React, { useEffect } from "react";

import "../user/user.css";
import Sidebar from "../../components/sidebar/Sidebar";
import TopNav, { TopNav2 } from "../../components/topnav/TopNav";

import { Route, Routes, Navigate } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import ThemeAction from "../../redux/actions/ThemeAction";
import HateMainPage from "../user/hate_main";
import HateKeywordPage from "../user/hate_keyword";
import ListPage from "../user/listpage";
import SettingPage from "../user/setting";
import SocialMainPage from "../user/social_main";
import SocialKeywordPage from "../user/social_keyword";

import MailPostModal from "../../components/mail/mailPostModal";

const PageFrame = ({ children }) => {
  return <div className="layout__content-main">{children}</div>;
};

const AdminRoute = () => {
  const others = useSelector((state) => state.Others);

  const dispatch = useDispatch();

  useEffect(() => {
    const themeClass = localStorage.getItem("themeMode", "theme-mode-light");

    const colorClass = localStorage.getItem("colorMode", "theme-mode-light");
    document.body.className = themeClass + " " + colorClass;
    dispatch(ThemeAction.setMode(themeClass));

    dispatch(ThemeAction.setColor(colorClass));
  }, [dispatch]);

  return (
    <div className={`layout`}>
      <MailPostModal />
      <Sidebar />
      <div className="layout__content">
        <Routes>
          <Route path="list/box" element={<TopNav2 title="받은 악플함" />} />
          <Route path="*" element={<TopNav />} />
        </Routes>
        <Routes>
          <Route
            path="hate/main"
            element={
              <PageFrame>
                <HateMainPage />
              </PageFrame>
            }
          />
          <Route
            path="hate/keyword"
            element={
              <PageFrame>
                <HateKeywordPage />
              </PageFrame>
            }
          />
          <Route
            path="social/main"
            element={
              <PageFrame>
                <SocialMainPage />
              </PageFrame>
            }
          />
          <Route
            path="social/keyword"
            element={
              <PageFrame>
                <SocialKeywordPage />
              </PageFrame>
            }
          />
          <Route path="list/box" element={<ListPage />} />
          <Route
            path="setting"
            element={
              <PageFrame>
                <SettingPage />
              </PageFrame>
            }
          />
          <Route path="*" element={<Navigate to="/admin/hate/main" />} />
        </Routes>
      </div>
    </div>
  );
};

export default AdminRoute;
