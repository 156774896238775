export const API_URL = "";
// export const API_URL = 'board.thinkpol.io';

export const API_PROTOCOL = "";
// export const API_PROTOCOL = "https://";

export const contentStatus = {
  dcinside: "/logo/dcinside.png",
  nate_pann: "/logo/natepan.png",
  naver_news: "/logo/naver.webp",
  youtube: "/logo/youtube.png",
  twitter: "/logo/twitter.png",
  instagram: "/logo/instagram.png",
  fmkorea: "/logo/fmkorea.png",
  hiphople: "/logo/hiphople.png",
  nate_news: "/logo/nate_news.png",
  ilbe: "/logo/ilbe.png",
  mlbpark: "/logo/mlbpark.png",
  melon: "/logo/melon.png",
  naver_cafe: "/logo/naver.webp",
  naver_blog: "/logo/naver.webp",
  instiz: "/logo/instiz.webp",
  theqoo: "/logo/theqoo.png",
  ssul: "/logo/ssul.png",
  issuya: "/logo/issuya.png",
  ygosu: "/logo/ygosu.jpg",
  genie: "/logo/genie.jpg",
  reddit: "/logo/reddit.png",
};

export const siteKorMap = {
  // 사이트 정렬
  coinpan: "코인판",
  dcinside: "디씨인사이드",
  fmkorea: "에펨코리아",
  genie: "지니",
  hiphople: "힙합엘이",
  ilbe: "일간베스트",
  instagram: "인스타그램",
  instiz: "인스티즈",
  issuya: "이슈야",
  melon: "멜론",
  mlbpark: "MLB파크",
  nate: "네이트", // 네이트 전부
  nate_news: "네이트뉴스",
  nate_pann: "네이트판",
  naver: "네이버", // 네이버 전부
  naver_blog: "네이버블로그",
  naver_cafe: "네이버카페",
  naver_news: "네이버뉴스",
  ssul: "네이트썰",
  theqoo: "더쿠",
  twitter: "트위터",
  ygosu: "와이고수",
  youtube: "유튜브",
  reddit: "레딧",
  // 나머지 그룹
  etc: "기타 사이트",
  music: "음원 사이트",
};
