import React from "react";
import Chart from "react-apexcharts";

import { useSelector } from "react-redux";
const BarOptions = (small_size) => ({
  options: {
    chart: {
      type: "bar",
      height: 270,
      stacked: true,
      toolbar: {
        show: true,
      },
      zoom: {
        enabled: false,
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            position: "bottom",
            offsetX: -10,
            offsetY: 0,
          },
        },
      },
    ],
    colors: ["#5443ff", "#6875FF", "#9FABFC", "#E0E5FF"],
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 5,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      type: "category",
    },
    legend: {
      position: "bottom",
      offsetX: 0,
      fontSize: "12px",
      markers: {
        width: 18,
        height: 18,
        strokeWidth: 0,
        radius: 8,
        offsetX: 0,
        offsetY: 0,
      },
      itemMargin: {
        horizontal: small_size ? 3 : 7,
        vertical: small_size ? 8 : 14,
      },
      onItemHover: {
        highlightDataSeries: false,
      },
    },
    fill: {
      opacity: 1,
    },
    yaxis: {
      labels: {
        style: {
          fontSize: "12px",
        },
      },
    },
    states: {
      hover: {
        filter: {
          type: "none",
        },
      },
    },
  },
});

const BarChart2 = ({ series, resize }) => {
  const themeReducer = useSelector((state) => state.ThemeReducer.mode);
  return (
    <Chart
      options={
        themeReducer === "theme-mode-dark"
          ? {
              ...BarOptions(resize < 1280).options,
              theme: { mode: "dark" },
              xaxis: {
                categories: series[0].xval,
                tickAmount: 7,
                type: "category",
                labels: {
                  style: {
                    fontSize: resize < 1280 ? "12px" : "14px",
                  },
                },
              },
            }
          : {
              ...BarOptions(resize < 1280).options,
              theme: { mode: "light" },
              xaxis: {
                categories: series[0].xval,
                tickAmount: 7,
                type: "category",
                labels: {
                  style: {
                    fontSize: resize < 1280 ? "12px" : "14px",
                  },
                },
              },
            }
      }
      data={[]}
      series={series}
      type="bar"
      height={resize < 1280 ? "225" : "275"}
    />
  );
};

export default BarChart2;
