import React from "react";
import { ThreeDots } from "react-loader-spinner";
import "./loading.css";
const TextLoad = ({ loading, type, children }) => {
  return (
    <span>
      <ThreeDots
        color="#5443ff"
        height={15}
        width={30}
        ariaLabel="falling-lines-loading"
      />
    </span>
  );
};
export default TextLoad;
