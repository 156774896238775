import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import { useDispatch } from "react-redux";

const useApi = (api, list = false) => {
  const [loading, setLoading] = useState(false);
  const [resolved, setResolved] = useState(null);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();

  const forceSetLoadingFalse = () => {
    setLoading(false);
  };

  const callApi = async (...args) => {
    setLoading(true);
    try {
      const data = await dispatch(api(...args));
      setResolved(data);
    } catch (error) {
      setError(error);
    }
    setLoading(false);
  };
  return [loading, resolved, error, callApi, forceSetLoadingFalse];
};

export default useApi;
