import React from "react";

const SidebarData = [
  {
    title: "악플 분석",
    path: "",
    icon: <i className="material-icons-outlined">leaderboard</i>,
    iconClosed: <i className="material-icons">keyboard_arrow_down</i>,
    iconOpened: <i className="material-icons">keyboard_arrow_up</i>,

    subNav: [
      {
        title: "Leaderboard",
        path: "/hate/main",
      },
      {
        title: "키워드 분석",
        path: "/hate/keyword",
      },
    ],
  },
  {
    title: "소셜 분석",
    path: "",
    icon: <i className="material-icons-outlined">pie_chart</i>,
    iconClosed: <i className="material-icons">keyboard_arrow_down</i>,
    iconOpened: <i className="material-icons">keyboard_arrow_up</i>,

    subNav: [
      {
        title: "Leaderboard",
        path: "/social/main",
      },
      {
        title: "키워드 분석",
        path: "/social/keyword",
      },
    ],
  },
  {
    title: "받은 악플함",
    path: "",
    icon: <i className="material-icons-outlined">email</i>,
    iconClosed: <i className="material-icons">keyboard_arrow_down</i>,
    iconOpened: <i className="material-icons">keyboard_arrow_up</i>,

    subNav: [
      {
        title: "악플함",
        path: "/list/box",
      },
      //   {
      //     title: "악플 처리현황",
      //     path: "/list/status",
      //     dev: true
      //   },
    ],
  },
  //   {
  //     title: "캘린더",
  //     path: "/schedule",
  //     icon: <i className="material-icons-outlined">calendar_month</i>,
  //     dev: true
  //   },
  {
    title: "설정",
    path: "/setting",
    icon: <i className="material-icons-outlined">settings</i>,
  },
];

export default SidebarData;
