import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import "./user.css";
import AllStatusCard from "../../components/status-card/allStatusCard";

import chartdata from "../../assets/JsonData/dummy/chart_data1.json";
import ZoomChart from "../../components/chart/main/zoomGraph";
import BarChart2 from "../../components/chart/main/barGraph2";
import BarChart1 from "../../components/chart/main/barGraph1";
import DonutChart from "../../components/chart/main/donut";
import dummyMain from "../../assets/JsonData/dummy/mainpage.json";
import useApi from "../../components/hooks/useApi";
import {
  getHateMainChart,
  getHateMainData,
} from "../../redux/actions/api_user";

import SlideCard from "../../components/status-card/SlideCard";
import DateRange from "../../components/datepick/dateRange";
import LoadFrame from "../../components/loading/LoadFrame";
import ApiErrorFrame from "../../components/error/ApiErrorFrame";
import { ErrorBoundary } from "react-error-boundary";
const HateMainPage = () => {
  const originDates = useSelector((state) => state.Others.dates);
  const [chartLoading, chartData, error1, getChart] = useApi(getHateMainChart);
  const [subLoading, subData, error2, getSub] = useApi(getHateMainData);
  const [dataToRender, setData] = useState({});
  const [chartToRender, setChart] = useState({});

  const [resize, setResize] = useState();
  const profile = useSelector((state) => state.Others.profile);

  const handleResize = () => {
    setResize(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (!profile.dummy) {
      getChart(originDates.type);
      getSub(originDates.type);
    }
  }, [originDates, profile]);

  useEffect(() => {
    if (error1) {
      setData(chartdata);
    } else if (chartData) {
      setChart(chartData.data);
    }
  }, [chartData, error1]);

  useEffect(() => {
    if (error2) {
      setData(dummyMain);
    } else if (subData) {
      setData(subData.data);
    }
  }, [subData, error2]);
  
  return (
    <div>
      <div className="page-header">
        <span>Leaderboard</span>
      </div>

      <div className="page-body">
        <div>
          <DateRange usePickModal={false} />
        </div>
        <div>
          <div className="d-row">
            <AllStatusCard
              {...dataToRender.allstatuscard}
              loading={subLoading}
              error={error2}
            />
          </div>
        </div>
        <div>
          <div className="card full-height">
            <div className="card__header">
              <h3>악플 수 변화</h3>
            </div>
            <ErrorBoundary FallbackComponent={ApiErrorFrame}>
              <LoadFrame loading={chartLoading || !chartToRender.range} error={error1}>
                {chartToRender.range ? (
                  <ZoomChart series={chartToRender} resize={resize} />
                ) : null}
              </LoadFrame>
            </ErrorBoundary>
          </div>
        </div>
        <div>
          <div className="d-row">
            <div className="col-6 left">
              <div className="card hate-contents">
                <div className="card__header">
                  <h3>커뮤니티 별 대표 게시글</h3>
                </div>
                <ErrorBoundary FallbackComponent={ApiErrorFrame}>
                  <LoadFrame loading={subLoading || !dataToRender.contents} error={error2}>
                    {dataToRender.contents ? (
                      <SlideCard data={dataToRender.contents} resize={resize} />
                    ) : null}
                  </LoadFrame>
                </ErrorBoundary>
              </div>
            </div>
            <div className="col-6 right vertical-card-area">
              <div className="hcomments-pie-container">
                <div className="card full-height">
                  <div className="card__header">
                    <h3>커뮤니티별 악플 비율</h3>
                  </div>
                  <div className="card__body">
                    <ErrorBoundary FallbackComponent={ApiErrorFrame}>
                      <LoadFrame loading={subLoading || !dataToRender.analysis} error={error2}>
                        {dataToRender.analysis ? (
                          <DonutChart
                            series={Object.values(
                              dataToRender.analysis.community,
                            )}
                            labels={Object.keys(dataToRender.analysis.community)}
                            resize={resize}
                          />
                        ) : null}
                      </LoadFrame>
                    </ErrorBoundary>
                  </div>
                </div>
              </div>
              <div className="hcomments-barchart-container">
                <div className="card full-height">
                  <div className="card__header">
                    <h3>악플 비율 상세</h3>
                  </div>
                  <div className="card__body">
                    <ErrorBoundary FallbackComponent={ApiErrorFrame}>
                      <LoadFrame loading={subLoading || !dataToRender.detailData} error={error2}>
                        {dataToRender?.detailData?.xval && (
                          <BarChart1
                            series={dataToRender.detailData}
                            resize={resize}
                          />
                        )}
                      </LoadFrame>
                    </ErrorBoundary>
                  </div>
                </div>
              </div>
              <div className="hcomments-barchart-container">
                <div className="card full-height">
                  {/* chart */}
                  <div className="card__header">
                    <h3>카테고리 별 악플 비율</h3>
                  </div>
                  <div className="card__body">
                    <ErrorBoundary FallbackComponent={ApiErrorFrame}>
                      <LoadFrame
                        loading={subLoading || !dataToRender.categoryData}
                        error={error2}
                      >
                        {dataToRender.categoryData ? (
                          <BarChart2
                            series={dataToRender.categoryData}
                            resize={resize}
                          />
                        ) : null}
                      </LoadFrame>
                    </ErrorBoundary>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HateMainPage;
