export const setHover = (hover) => {
  return {
    type: "SET_HOVER",
    payload: hover,
  };
};
export const setClick = (click) => {
  return {
    type: "SET_CLICK",
    payload: click,
  };
};
