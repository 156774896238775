const CloudOptions = {
  colors: ["#2986cc", "#bf9000", "#f44336"],
  enableTooltip: false,
  deterministic: true,
  fontFamily: "tway",
  fontSizes: [10, 60],
  fontStyle: "normal",
  fontWeight: "normal",
  padding: 1,
  rotations: 1,
  rotationAngles: [0, 90],
  scale: "sqrt",
  spiral: "archimedean",
  transitionDuration: 1000,
};

export default CloudOptions;
