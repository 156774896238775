import React, { useRef, useState, useEffect } from "react";

import { useDispatch } from "react-redux";

import ThemeAction from "../../redux/actions/ThemeAction";

import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import "./user.css";
import { Tag, X } from "react-feather";
import {
  postConfigBoard,
  postConfigKeyword,
  getConfigBoard,
  getConfigKeyword,
} from "../../redux/actions/api_user";
import useApi from "../../components/hooks/useApi";
import useApiSimple from "../../components/hooks/useApiSimple";
import { changePassword } from "../../redux/actions/auth";

const mode_settings = [
  {
    id: "light",
    name: "Light",
    background: "light-background",
    class: "theme-mode-light",
  },
  {
    id: "dark",
    name: "Dark",
    background: "dark-background",
    class: "theme-mode-dark",
  },
];

const color_settings = [
  {
    id: "blue",
    name: "Blue",
    background: "blue-color",
    class: "theme-color-blue",
  },
  {
    id: "red",
    name: "Red",
    background: "red-color",
    class: "theme-color-red",
  },
  {
    id: "cyan",
    name: "Cyan",
    background: "cyan-color",
    class: "theme-color-cyan",
  },
  {
    id: "green",
    name: "Green",
    background: "green-color",
    class: "theme-color-green",
  },
  {
    id: "orange",
    name: "Orange",
    background: "orange-color",
    class: "theme-color-orange",
  },
];

const SettingPage = () => {
  const input_ref = useRef(null);
  const menu_toggle_ref = useRef(null);
  const [loading1, boardList, error1, getBoard] = useApi(getConfigBoard);
  const [loading2, boardList2, error2, postBoard] = useApi(postConfigBoard);
  const [loading3, keywordList, error3, getKeyword] = useApi(getConfigKeyword);
  const [loading4, keywordList2, error4, postKeyword] =
    useApi(postConfigKeyword);
  const [changePWLoading, changePWAction] = useApiSimple(changePassword);
  const [currMode, setcurrMode] = useState("light");

  const [currColor, setcurrColor] = useState("blue");
  const [tempUrl, setTempUrl] = useState("");
  const [tempName, setTempName] = useState("");
  const [password, setPassword] = useState("");
  const [newpassword, setNewpassword] = useState("");
  const [newpasswordConfirm, setNewpasswordConfirm] = useState("");
  const [errors, setErrors] = useState(false);
  const onChangeUrl = (e) => {
    setTempUrl(e.target.value);
  };
  const onChangeName = (e) => {
    setTempName(e.target.value);
  };
  const onSubmit = (e) => {
    e.preventDefault();
    if (newpassword === newpasswordConfirm) {
      changePWAction(password, newpassword);
    } else {
      alert("새 비밀번호가 일치하지 않습니다!");
    }
    // return false;
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      onSubmit(e);
    }
  };

  const dispatch = useDispatch();

  const setMode = (mode) => {
    setcurrMode(mode.id);
    localStorage.setItem("themeMode", mode.class);
    dispatch(ThemeAction.setMode(mode.class));
  };

  const setColor = (color) => {
    setcurrColor(color.id);
    localStorage.setItem("colorMode", color.class);
    dispatch(ThemeAction.setColor(color.class));
  };

  useEffect(() => {
    getBoard();
  }, [boardList2]);

  useEffect(() => {
    getKeyword();
  }, [keywordList2]);

  // useEffect(() => {
  //   postBoard(url);
  // }, [url]);
  // useEffect(() => {
  //   if (boardList) {
  //     setUrl(boardList.data);
  //   }
  // }, [boardList]);

  // useEffect(() => {
  //   postKeyword(name);
  // }, [name]);
  // useEffect(() => {
  //   if (keywordList) {
  //     setName(keywordList.data);
  //   }
  // }, [keywordList]);

  useEffect(() => {
    const themeClass = mode_settings.find(
      (e) => e.class === localStorage.getItem("themeMode", "theme-mode-light"),
    );

    const colorClass = color_settings.find(
      (e) => e.class === localStorage.getItem("colorMode", "theme-mode-light"),
    );

    if (themeClass !== undefined) setcurrMode(themeClass.id);

    if (colorClass !== undefined) setcurrColor(colorClass.id);
  }, []);

  useEffect(() => {
    document.body.className =
      "theme-mode-" + currMode + " theme-color-" + currColor;
  }, [currMode, currColor]);

  return (
    <div>
      <h2 className="page-header">설정</h2>
      <div className="d-row setting-row">
        <div className="col-6 left">
          <div className="card">
            <div className="card__header">
              <h3>키워드 추가요청</h3>
            </div>
            <div className="card__body">
              <div className="TagForm">
                <input
                  type="text"
                  onChange={onChangeName}
                  value={tempName}
                  placeholder="별명을 추가해주세요..."
                  onKeyPress={(event) => {
                    if (event.key === "Enter") {
                      postKeyword({ name: tempName });
                      setTempName("");
                    }
                  }}
                />
                <button
                  onClick={() => {
                    postKeyword({ name: tempName });
                    setTempName("");
                  }}
                  className="normal-button"
                >
                  Add
                </button>
              </div>
              <ul className="TagList">
                {keywordList?.data.map((item) => (
                  <li key={item} className="Tag">
                    {item}
                    <X
                      className="TagIcon"
                      size="16"
                      onClick={() => {
                        alert("키워드 취소는 관리자에게 문의바랍니다.");
                      }}
                    />
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className="col-6 right">
          <div className="card">
            <div className="card__header">
              <h3>게시판 추가요청</h3>
            </div>
            <div className="card__body">
              <div className="TagForm">
                <input
                  type="text"
                  onChange={onChangeUrl}
                  value={tempUrl}
                  placeholder="탐색 게시판을 추가해주세요..."
                  onKeyPress={(event) => {
                    if (event.key === "Enter") {
                      postBoard({ url: tempUrl });
                      setTempUrl("");
                    }
                  }}
                />
                <button
                  onClick={() => {
                    postBoard({ url: tempUrl });
                    setTempUrl("");
                  }}
                  className="normal-button"
                >
                  Add
                </button>
              </div>

              <ul className="TagList2">
                {boardList?.data.map((item) => (
                  <li className="Tag2" key={item["url"]}>
                    {item["url"]}:
                    {item["status"] == "AC" ? "  등록완료" : "  대기중"}
                    <X
                      className="TagIcon"
                      size="16"
                      onClick={() => {
                        alert("키워드 취소는 관리자에게 문의바랍니다.");
                      }}
                    />
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className="col-6 left">
          <div className="card">
            <div className="card__header">
              <h3>비밀번호 설정</h3>
            </div>
            <div className="card__body">
              <TextField
                class="password"
                required
                id="old"
                label="현재 비밀번호"
                variant="outlined"
                fullWidth
                type="password"
                onChange={(e) => setPassword(e.target.value)}
                onKeyPress={handleKeyPress}
              />
              <TextField
                class="password"
                required
                id="new"
                label="새 비밀번호"
                variant="outlined"
                fullWidth
                type="password"
                onChange={(e) => setNewpassword(e.target.value)}
                onKeyPress={handleKeyPress}
              />
              <TextField
                class="password"
                required
                id="new2"
                label="새 비밀번호 확인"
                variant="outlined"
                fullWidth
                type="password"
                onChange={(e) => setNewpasswordConfirm(e.target.value)}
                onKeyPress={handleKeyPress}
              />
              <button className="normal-button" onClick={onSubmit}>
                비밀번호 변경
              </button>
            </div>
          </div>
        </div>
        <div className="col-6 right">
          <div className="card">
            <div className="card__header">
              <h3>테마 변경</h3>
            </div>
            <div className="card__body">
              <div className="d-row">
                <div className="col-6">
                  <span>Choose mode</span>
                  <ul className="mode-list">
                    {mode_settings.map((item, index) => (
                      <li
                        key={index}
                        onClick={() => setColor(item)}
                        role="presentation"
                      >
                        <div
                          className={`mode-list__color ${item.background} ${
                            item.id === currMode ? "active" : ""
                          }`}
                        >
                          <i className="bx bx-check"></i>
                        </div>
                        <span>{item.name}</span>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="col-6">
                  <span>Choose color</span>
                  <ul className="mode-list">
                    {color_settings.map((item, index) => (
                      <li
                        key={index}
                        onClick={() => setColor(item)}
                        role="presentation"
                      >
                        <div
                          className={`mode-list__color ${item.background} ${
                            item.id === currColor ? "active" : ""
                          }`}
                        >
                          <i className="bx bx-check"></i>
                        </div>
                        <span>{item.name}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingPage;
