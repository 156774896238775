import { ApiService } from "../../ApiService";
import { authHeader, authClientID } from "../../helpers/authHeader";

const apiService = new ApiService();

export const getClients = () => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    apiService
      .getClients(authHeader(getState))
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getHateMainChart = (type) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    let querystring = "?qtype=" + type;
    apiService
      .getHateMainChart(
        authHeader(getState),
        authClientID(getState),
        querystring,
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getHateMainData = (type) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    let querystring = "?qtype=" + type;
    apiService
      .getHateMainData(
        authHeader(getState),
        authClientID(getState),
        querystring,
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getHateKeywordData =
  (start_time, end_time, type) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      let querystring =
        "?start_time=" + start_time + "&end_time=" + end_time + "&type=" + type;
      apiService
        .getHateKeywordData(
          authHeader(getState),
          authClientID(getState),
          querystring,
        )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

export const getHateKeywordSubData =
  (start_time, end_time, type, keyword) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      let querystring =
        "?start_time=" +
        start_time +
        "&end_time=" +
        end_time +
        "&type=" +
        type +
        "&keyword=" +
        keyword;
      apiService
        .getHateKeywordSubData(
          authHeader(getState),
          authClientID(getState),
          querystring,
        )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

export const getSocialMainChart = (type, lang) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    let querystring = "?qtype=" + type + "&lang=" + lang;
    apiService
      .getSocialMainChart(
        authHeader(getState),
        authClientID(getState),
        querystring,
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getSocialMainData = (type, lang) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    let querystring = "?qtype=" + type + "&lang=" + lang;
    apiService
      .getSocialMainData(
        authHeader(getState),
        authClientID(getState),
        querystring,
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getSocialKeywordData =
  (start_time, end_time, type, lang) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      let querystring =
        "?start_time=" +
        start_time +
        "&end_time=" +
        end_time +
        "&type=" +
        type +
        "&lang=" +
        lang;
      apiService
        .getSocialKeywordData(
          authHeader(getState),
          authClientID(getState),
          querystring,
        )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

export const getSocialKeywordSubData =
  (start_time, end_time, type, keyword, lang) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      let querystring =
        "?start_time=" +
        start_time +
        "&end_time=" +
        end_time +
        "&type=" +
        type +
        "&keyword=" +
        keyword +
        "&lang=" +
        lang;
      apiService
        .getSocialKeywordSubData(
          authHeader(getState),
          authClientID(getState),
          querystring,
        )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

export const getHateList =
  (status, page, limit, keyword, filter) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      let querystring =
        "?status=" + status + "&page=" + page + "&limit=" + limit;
      keyword && (querystring += "&keyword=" + keyword);
      filter?.site && (querystring += "&site_name=" + filter.site);
      filter?.user && (querystring += "&has_username=" + filter.user);
      apiService
        .getHateList(authHeader(getState), authClientID(getState), querystring)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

export const putHateList = (status, list) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    let querystring = "?status=" + status;
    apiService
      .putHateList(
        authHeader(getState),
        authClientID(getState),
        list,
        querystring,
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getHateListNum = () => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    apiService
      .getHateListNum(authHeader(getState), authClientID(getState))
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getConfigBoard = () => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    apiService
      .getConfigBoard(authHeader(getState), authClientID(getState))
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const postConfigBoard = (list) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    apiService
      .postConfigBoard(authHeader(getState), authClientID(getState), list)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const getConfigKeyword = () => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    apiService
      .getConfigKeyword(authHeader(getState), authClientID(getState))
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const postConfigKeyword = (list) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    apiService
      .postConfigKeyword(authHeader(getState), authClientID(getState), list)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getHateCalendar =
  (start_time, end_time) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      let querystring = "?start_time=" + start_time + "&end_time=" + end_time;
      apiService
        .getHateCalendar(
          authHeader(getState),
          authClientID(getState),
          querystring,
        )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

export const getDataCalendar = (type) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    apiService
      .getDataCalendar(authHeader(getState), authClientID(getState))
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const postDataCalendar = (list) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    apiService
      .postDataCalendar(authHeader(getState), authClientID(getState), list)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getHateCSV = (status) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    let querystring = "?status=" + status;
    let header = authHeader(getState);
    header.responseType = "blob";
    apiService
      .getHateCSV(header, authClientID(getState), querystring)
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;

        const disposition = res.headers["content-disposition"];
        const fileName = decodeURI(
          disposition
            .match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/)[1]
            .replace(/['"]/g, ""),
        );
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getHateOptions = (status) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    let querystring = "?simple=1&all=1&status=" + status;
    apiService
      .getHateOptions(authHeader(getState), authClientID(getState), querystring)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const ignorePost = (data) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    data.client_id = authClientID(getState);
    apiService
      .ignorePost(authHeader(getState), data)
      .then((res) => {
        resolve(res.data);
        window.alert("api 성공");
      })
      .catch((err) => {
        reject(err);
        window.alert(err?.response?.data?.detail);
      });
  });
};

export const postHateCheck = () => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    apiService
      .postHateCheck(authHeader(getState))
      .then((res) => {
        resolve(res.data);
        window.alert("트리거 전달 완료");
      })
      .catch((err) => {
        reject(err);
        window.alert(err?.response?.data?.detail);
      });
  });
};
