import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  DropdownButton,
  Dropdown,
  Overlay,
  Button,
  OverlayTrigger,
  Popover,
} from "react-bootstrap";

import MailTable from "../../components/mail/mailList";
import "./listpage.css";
import {
  getHateList,
  getHateListNum,
  putHateList,
  getHateCSV,
  getHateOptions,
  postHateCheck,
} from "../../redux/actions/api_user";
import useApiSimple from "../../components/hooks/useApiSimple";
import useApi from "../../components/hooks/useApi";
import LoadFrame from "../../components/loading/LoadFrame";
import TextLoad from "../../components/loading/TextLoad";
import { generateRange } from "../../function";
import RenderWrapper from "../../components/display/renderWrapper";
import { siteKorMap } from "../../config";
import ApiErrorFrame from "../../components/error/ApiErrorFrame";
import { ErrorBoundary, useErrorBoundary } from "react-error-boundary";

const ListPage = () => {
  const auth = useSelector((state) => state.auth);
  const statusNew = auth.user.user_service.use_confirm ? "CF" : "UC";

  const [loading, dataList, error, getList] = useApi(getHateList);
  const pageLimit = 10;
  const [loading2, dataNum, error2, getNum] = useApi(getHateListNum);
  const [loading3, dataList2, error3, putList] = useApi(putHateList);
  const [loading4, getCSV] = useApiSimple(getHateCSV, false);
  const [loading5, hateOptions, error5, getHateOption] = useApi(getHateOptions);
  const [loading6, postHateCheckFunc] = useApiSimple(postHateCheck);

  const [hateList, setData] = useState([]);
  const [maxPage, setMaxPage] = useState(1);
  const [largePage, setLargePage] = useState(0);
  const [page, setPage] = useState(1);
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [checkedItems, setCheckList] = useState([]);
  const [status, setStatus] = useState(statusNew);
  const [keywordStaging, setKeywordStaging] = useState("");
  const [keyword, setKeyword] = useState(null);
  const [limit, setLimit] = useState(10);
  const [numbers, setNumbers] = useState({
    TR: 0,
    DE: 0,
    DR: 0,
    RE: 0,
    RR: 0,
    UC: 0,
    CF: 0,
    DF: 0,
  });
  const [filterShow, setFilterShow] = useState(false);
  const [options, setOptions] = useState([]);
  const [filter, setFilter] = useState({});
  const [filterStaging, setFilterStaging] = useState({});

  const selectPage = (page) => {
    setPage(page);
    getList(status, page, limit, keyword, filter);
  };

  const selectLimit = (limit) => {
    setLimit(limit);
    setPage(1);
    setLargePage(0);
    getList(status, 1, limit, keyword, filter);
  };

  const selectLargePage = (largePage) => {
    let page = largePage * pageLimit + 1;
    setPage(page);
    setLargePage(largePage);
    getList(status, page, limit, keyword, filter);
  };

  const selectStatus = (status) => {
    setStatus(status);
    setPage(1);
    setLargePage(0);
    setFilterShow(false);
    setFilter({});
    setFilterStaging({});
    setKeyword(null);
    getList(status, 1, limit, null, filter);
  };

  const allCheckedHandler = (isChecked) => {
    if (isChecked) {
      setCheckList(hateList.map((item) => [item.id, item.type]));
      setIsAllChecked(true);
    } else {
      setCheckList([]);
      setIsAllChecked(false);
    }
  };

  const registerKeyword = () => {
    setKeyword(keywordStaging);
    setPage(1);
    setLargePage(0);
    getList(status, 1, limit, keywordStaging, filter);
    setKeywordStaging("");
  };

  const deleteKeyword = () => {
    setKeyword(null);
    setPage(1);
    setLargePage(0);
    getList(status, 1, limit, null, filter);
  };

  const handleLargePage = (direction) => {
    if (direction === -1 && largePage > 0) {
      selectLargePage(largePage - 1);
    } else if (direction === 1 && largePage < Math.floor(maxPage / pageLimit)) {
      selectLargePage(largePage + 1);
    }
  };

  const handleEnterKey = (e) => {
    if (e.key === "Enter") {
      registerKeyword();
    }
  };

  const genPages = () => {
    let first_page = largePage * pageLimit + 1;
    let last_page = Math.min((largePage + 1) * pageLimit, maxPage);

    return generateRange(first_page, last_page).map((page_no) => (
      <button
        key={page_no}
        className={`table__pagination-item ${page === page_no ? "active" : ""}`}
        onClick={() => selectPage(page_no)}
      >
        {page_no}
      </button>
    ));
  };

  const GenOptions = () => {
    const {showBoundary} = useErrorBoundary();
    if (error5){
      showBoundary(error5);
    }
    if (options) {
      let returnArray = [
        <Dropdown.Item
          key={1000}
          eventKey={"선택없음"}
          onClick={() => setFilterStaging({ ...filterStaging, site: null })}
        >
          선택없음
        </Dropdown.Item>,
      ];
      Object.keys(options).forEach((el, index) => {
        returnArray.push(
          <Dropdown.Item
            key={index}
            eventKey={el}
            onClick={() => setFilterStaging({ ...filterStaging, site: el })}
          >
            {siteKorMap[el]}
          </Dropdown.Item>,
        );
      });

      return (
        <DropdownButton
          id="dropdown-basic-button-filter"
          className="list-menu-button"
          title={filterStaging.site || "사이트 선택"}
        >
          {returnArray}
        </DropdownButton>
      );
    } else {
      return null;
    }
  };

  const handleToggle = () => {
    if (!filterShow) {
      getHateOption(status);
    }
    setFilterShow(!filterShow);
  };

  const confirmFilter = () => {
    setFilter(filterStaging);
    setPage(1);
    setLargePage(0);
    handleToggle();
  };

  const clearSiteFilter = () => {
    setPage(1);
    setLargePage(0);
    setFilter({
      ...filter,
      site: null,
    });
    setFilterStaging({
      ...filterStaging,
      site: null,
    });
  };

  const clearUserFilter = () => {
    setPage(1);
    setLargePage(0);
    setFilter({
      ...filter,
      user: null,
    });
    setFilterStaging({
      ...filterStaging,
      user: null,
    });
  };

  const calculateLargePage = (max_page) => {
    return Math.floor(max_page / pageLimit) - (max_page % 10 === 0 ? 1 : 0);
  };

  const filterPopOver = (
    <Popover>
      <Popover.Body>
        <div className="filter-box">
          <ErrorBoundary FallbackComponent={ApiErrorFrame}>
            <div className="filter-box-site"><GenOptions/></div>
            <div className="filter-box-user">
              <div>로그인한 유저만</div>
              <div>
                <input
                  type="checkbox"
                  checked={filterStaging.user || false}
                  className="filter-user-check"
                  defaultValue={false}
                  onChange={(e) =>
                    setFilterStaging({
                      ...filterStaging,
                      user: !filterStaging.user,
                    })
                  }
                />
              </div>
            </div>
          </ErrorBoundary>
        </div>
        <div className="filter-buttons">
          <div
            onClick={handleToggle}
            onKeyDown={handleToggle}
            role="button"
            tabIndex={0}
          >
            취소
          </div>
          <div
            onClick={confirmFilter}
            onKeyDown={confirmFilter}
            role="button"
            tabIndex={0}
          >
            확인
          </div>
        </div>
      </Popover.Body>
    </Popover>
  );

  useEffect(() => {
    if (dataList?.data) {
      setData(dataList.data.current_page_data);
      setMaxPage(dataList.data.max_page);
      if (page > dataList.data.max_page) {
        setPage(dataList.data.max_page);
        setLargePage(calculateLargePage(dataList.data.max_page));
      }
      setCheckList([]);
      setIsAllChecked(false);
    }
  }, [dataList]);

  useEffect(() => {
    getNum();
  }, []);

  //댓글 위치 변경시 리셋
  useEffect(() => {
    if (dataList2) {
      getNum();
      getList(status, page, limit, keyword, filter);
    }
  }, [dataList2]);

  useEffect(() => {
    if (dataNum?.data) {
      setNumbers(dataNum.data);
    }
  }, [dataNum]);

  useEffect(() => {
    if (hateOptions) {
      setOptions(hateOptions.data);
    }
  }, [hateOptions]);

  useEffect(() => {
    getList(status, page, limit, keyword, filter);
  }, [filter]);

  return (
    <div className="listpage">
      <div className="d-row frame">
        <div className="col-2 mail-side">
          <div className="list">
            <RenderWrapper condition={auth.user.role === "admin"}>
              <div
                onClick={() => selectStatus("UC")}
                onKeyDown={() => selectStatus("UC")}
                className={`element ${status == "UC" && "clicked"}`}
                role="button"
                tabIndex={0}
              >
                <div>분류 대기중</div>
                {loading2 ? <TextLoad /> : <span>{numbers["UC"]}</span>}
              </div>
            </RenderWrapper>
            <div
              onClick={() => selectStatus(statusNew)}
              onKeyDown={() => selectStatus(statusNew)}
              className={`element ${status == statusNew && "clicked"}`}
              role="button"
              tabIndex={0}
            >
              <div>악플함</div>
              {loading2 ? <TextLoad /> : <span>{numbers[statusNew]}</span>}
            </div>
            <div
              onClick={() => selectStatus("RR")}
              onKeyDown={() => selectStatus("RR")}
              className={`element ${status == "RR" && "clicked"}`}
              role="button"
              tabIndex={0}
            >
              <div>고소 접수 예정</div>
              {loading2 ? <TextLoad /> : <span>{numbers["RR"]}</span>}
            </div>
            <div
              onClick={() => selectStatus("DR")}
              onKeyDown={() => selectStatus("DR")}
              className={`element ${status == "DR" && "clicked"}`}
              role="button"
              tabIndex={0}
            >
              <div>삭제 요청 예정</div>
              {loading2 ? <TextLoad /> : <span>{numbers["DR"]}</span>}
            </div>
            <div
              onClick={() => selectStatus("DE")}
              onKeyDown={() => selectStatus("DE")}
              className={`element ${status == "DE" && "clicked"}`}
              role="button"
              tabIndex={0}
            >
              <div>삭제 완료 목록</div>
              {loading2 ? <TextLoad /> : <span>{numbers["DE"]}</span>}
            </div>
            <div
              onClick={() => selectStatus("DF")}
              onKeyDown={() => selectStatus("DF")}
              className={`element ${status == "DF" && "clicked"}`}
              role="button"
              tabIndex={0}
            >
              <div>삭제 실패 목록</div>
              {loading2 ? <TextLoad /> : <span>{numbers["DF"]}</span>}
            </div>
            <RenderWrapper condition={auth.user.role === "admin"}>
              <div
                onClick={() => selectStatus("TR")}
                onKeyDown={() => selectStatus("TR")}
                className={`element ${status == "TR" && "clicked"}`}
                role="button"
                tabIndex={0}
              >
                <div>휴지통</div>
                {loading2 ? <TextLoad /> : <span>{numbers["TR"]}</span>}
              </div>
            </RenderWrapper>
          </div>
        </div>
        <div className="col-10 mail-main">
          <div className="d-row mail-top1">
            <div className="mail-title">새 목록</div>
            <div className="d-row middle-item">
              <div className="searchbar">
                <input
                  type="text"
                  className="inputBox"
                  placeholder="악플 검색"
                  onChange={(e) => setKeywordStaging(e.target.value)}
                  onKeyPress={handleEnterKey}
                  value={keywordStaging}
                />
                <button
                  type="submit"
                  className="searchButton"
                  onClick={registerKeyword}
                >
                  <i className="bx bx-search"></i>
                </button>
              </div>
              <div className="menu">
                {/* <i className="bx bx-menu" /> */}
                <DropdownButton
                  id="dropdown-basic-button"
                  className="list-menu-button"
                  title="보기"
                >
                  <Dropdown.Item eventKey="10" onClick={() => selectLimit(10)}>
                    10개씩 보기
                  </Dropdown.Item>
                  <Dropdown.Item eventKey="20" onClick={() => selectLimit(20)}>
                    20개씩 보기
                  </Dropdown.Item>
                  <Dropdown.Item eventKey="50" onClick={() => selectLimit(50)}>
                    50개씩 보기
                  </Dropdown.Item>
                  <Dropdown.Item
                    eventKey="100"
                    onClick={() => selectLimit(100)}
                  >
                    100개씩 보기
                  </Dropdown.Item>
                </DropdownButton>
              </div>
              <OverlayTrigger
                rootClose
                onHide={handleToggle}
                className="menu"
                trigger={null}
                show={filterShow}
                placement="right"
                overlay={filterPopOver}
              >
                <div
                  className="menu list-menu-filter"
                  onClick={() => {
                    handleToggle();
                  }}
                  onKeyDown={() => {
                    handleToggle();
                  }}
                  role="button"
                  tabIndex={0}
                >
                  <span>필터</span>
                  <span className="material-icons-outlined">settings</span>
                </div>
              </OverlayTrigger>
            </div>
            <div className="d-row rightest-item">
              <button onClick={() => getCSV(status)}>전체 목록 다운로드</button>
              {loading4 && (
                <span>
                  <TextLoad />
                </span>
              )}
            </div>
          </div>
          <div className="d-row mail-top2">
            <input
              type="checkbox"
              checked={isAllChecked}
              className="checkbox"
              onChange={(e) => allCheckedHandler(!isAllChecked)}
            />
            <div className="buttons">
              <button
                className="small-button"
                onClick={() => putList("RR", checkedItems)}
                onKeyDown={() => putList("RR", checkedItems)}
              >
                고소 신청
              </button>
              <div className="row-line" />
              <button
                onClick={() => putList("DR", checkedItems)}
                onKeyDown={() => putList("DR", checkedItems)}
                className="small-button"
              >
                삭제 요청
              </button>
              <div className="row-line" />
              <button
                onClick={() => putList("TR", checkedItems)}
                onKeyDown={() => putList("TR", checkedItems)}
                className="small-button"
              >
                지우기
              </button>
              <RenderWrapper condition={auth.user.role === "admin"}>
                <div className="row-line" />
                <button
                  onClick={() => putList("CF", checkedItems)}
                  onKeyDown={() => putList("CF", checkedItems)}
                  className="small-button"
                >
                  악플함으로
                </button>
                <div className="row-line" />
                <button
                  onClick={() => putList("DE", checkedItems)}
                  onKeyDown={() => putList("DE", checkedItems)}
                  className="small-button"
                >
                  삭제 완료로
                </button>
                <div className="row-line" />
                <button
                  onClick={() => putList("DF", checkedItems)}
                  onKeyDown={() => putList("DF", checkedItems)}
                  className="small-button"
                >
                  삭제 실패로
                </button>
              </RenderWrapper>
            </div>
            <div className="buttons">
              <RenderWrapper
                condition={auth.user.role === "admin" && status === "DR"}
              >
                <button
                  onClick={() => postHateCheckFunc()}
                  onKeyDown={() => postHateCheckFunc()}
                  className="small-button"
                >
                  자동 삭제 트리거
                </button>
              </RenderWrapper>
            </div>
            {filter.site ? (
              <div className="filter-current">
                <span>사이트 필터링 : {siteKorMap[filter.site]}</span>
                <span
                  className="material-icons-outlined"
                  onClick={clearSiteFilter}
                  onKeyDown={clearUserFilter}
                  role="button"
                  tabIndex={0}
                >
                  close
                </span>
              </div>
            ) : null}
            {filter.user ? (
              <div className="filter-current">
                <span>로그인 유저 필터링 : ON</span>
                <span
                  className="material-icons-outlined"
                  onClick={clearUserFilter}
                  onKeyDown={clearUserFilter}
                  role="button"
                  tabIndex={0}
                >
                  close
                </span>
              </div>
            ) : null}

            {keyword && (
              <div className="d-row rightest-item">
                <span className="text">{`검색어: ${keyword}`}</span>

                <button
                  className="table__pagination-item"
                  onClick={deleteKeyword}
                >
                  <i className="bx bx-reset"></i>
                </button>
              </div>
            )}
          </div>

          <div className="mail-content">
            <ErrorBoundary FallbackComponent={ApiErrorFrame}>
              <LoadFrame loading={loading} error={error}>
                <MailTable
                  dataShow={hateList}
                  listState={[checkedItems, setCheckList]}
                  allCheckState={[isAllChecked, setIsAllChecked]}
                />

                <div className="table__pagination">
                  <button
                    className="table__pagination-item"
                    onClick={() => {
                      selectLargePage(0);
                    }}
                    disabled={largePage === 0}
                  >
                    <i className="bx bx-chevrons-left" />
                  </button>

                  <button
                    className="table__pagination-item"
                    onClick={() => {
                      handleLargePage(-1);
                    }}
                    disabled={largePage === 0}
                  >
                    <i className="bx bx-chevron-left" />
                  </button>

                  {genPages()}
                  <button
                    className="table__pagination-item"
                    onClick={() => {
                      handleLargePage(1);
                    }}
                    disabled={
                      largePage ===
                      Math.floor(maxPage / pageLimit) -
                        (maxPage % 10 === 0 ? 1 : 0)
                    }
                  >
                    <i className="bx bx-chevron-right" />
                  </button>

                  <button
                    className="table__pagination-item"
                    onClick={() => {
                      selectLargePage(Math.floor(maxPage / pageLimit));
                    }}
                    disabled={
                      largePage ===
                      Math.floor(maxPage / pageLimit) -
                        (maxPage % 10 === 0 ? 1 : 0)
                    }
                  >
                    <i className="bx bx-chevrons-right" />
                  </button>
                </div>
              </LoadFrame>
            </ErrorBoundary>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListPage;
