import { combineReducers } from "redux";
import auth from "./auth";
import ThemeReducer from "./ThemeReducer";
import Others from "./OthersReducer";
import HoverReducer from "./KeywordReducer";

export default combineReducers({
  auth,
  ThemeReducer,
  Others,
  HoverReducer,
});
