import axios from "axios";
import { API_URL, API_PROTOCOL } from "./config";

const URL = API_PROTOCOL + API_URL;

export class ApiService {
  signin(body) {
    return axios.post(`${URL}/users/auth/signin/`, body);
  }

  signout(authHeader) {
    return axios.post(`${URL}/users/auth/signout/`, null, authHeader);
  }

  checkAuth(authHeader) {
    return axios.get(`${URL}/users/auth/user/`, authHeader);
  }

  changePassword(authHeader, userID, body) {
    return axios.patch(
      `${URL}/users/auth/changepw/${userID}/`,
      body,
      authHeader,
    );
  }

  getClients(authHeader) {
    return axios.get(`${URL}/users/client/`, authHeader);
  }

  getHateMainChart(authHeader, clientID, querystring) {
    return axios.get(
      `${URL}/users/client/hchart/${clientID}/${querystring}`,
      authHeader,
    );
  }

  getHateMainData(authHeader, clientID, querystring) {
    return axios.get(
      `${URL}/users/client/hmaindata/${clientID}/${querystring}`,
      authHeader,
    );
  }

  getHateKeywordData(authHeader, clientID, querystring) {
    return axios.get(
      `${URL}/users/client/hkeyworddata/${clientID}/${querystring}`,
      authHeader,
    );
  }
  getHateKeywordSubData(authHeader, clientID, querystring) {
    return axios.get(
      `${URL}/users/client/hkeywordsubdata/${clientID}/${querystring}`,
      authHeader,
    );
  }

  getSocialMainChart(authHeader, clientID, querystring) {
    return axios.get(
      `${URL}/users/client/schart/${clientID}/${querystring}`,
      authHeader,
    );
  }

  getSocialMainData(authHeader, clientID, querystring) {
    return axios.get(
      `${URL}/users/client/smaindata/${clientID}/${querystring}`,
      authHeader,
    );
  }

  getSocialKeywordData(authHeader, clientID, querystring) {
    return axios.get(
      `${URL}/users/client/skeyworddata/${clientID}/${querystring}`,
      authHeader,
    );
  }
  getSocialKeywordSubData(authHeader, clientID, querystring) {
    return axios.get(
      `${URL}/users/client/skeywordsubdata/${clientID}/${querystring}`,
      authHeader,
    );
  }

  getHateList(authHeader, clientID, querystring) {
    return axios.get(
      `${URL}/users/client/hcomment/${clientID}/${querystring}`,
      authHeader,
    );
  }

  putHateList(authHeader, clientID, body, querystring) {
    return axios.put(
      `${URL}/users/client/hcomment/${clientID}/${querystring}`,
      body,
      authHeader,
    );
  }
  getHateListNum(authHeader, clientID) {
    return axios.get(
      `${URL}/users/client/hcommentsub/${clientID}/`,
      authHeader,
    );
  }

  getHateCSV(authHeader, clientID, querystring) {
    return axios.get(
      `${URL}/users/client/hatecsv/${clientID}/${querystring}`,
      authHeader,
    );
  }

  getHateOptions(authHeader, clientID, querystring) {
    return axios.get(
      `${URL}/users/client/hateoption/${clientID}/${querystring}`,
      authHeader,
    );
  }

  getConfigBoard(authHeader, clientID) {
    return axios.get(
      `${URL}/users/client/configboard/${clientID}/`,
      authHeader,
    );
  }
  postConfigBoard(authHeader, clientID, body) {
    return axios.post(
      `${URL}/users/client/configboard/${clientID}/`,
      body,
      authHeader,
    );
  }

  getConfigKeyword(authHeader, clientID) {
    return axios.get(
      `${URL}/users/client/configkeyword/${clientID}/`,
      authHeader,
    );
  }

  postConfigKeyword(authHeader, clientID, body) {
    return axios.post(
      `${URL}/users/client/configkeyword/${clientID}/`,
      body,
      authHeader,
    );
  }

  getHateCalendar(authHeader, clientID, querystring) {
    return axios.get(
      `${URL}/users/client/hatecalendar/${clientID}/${querystring}`,
      authHeader,
    );
  }
  getDataCalendar(authHeader, clientID) {
    return axios.get(
      `${URL}/users/client/datacalendar/${clientID}/`,
      authHeader,
    );
  }
  postDataCalendar(authHeader, clientID, body) {
    return axios.post(
      `${URL}/users/client/datacalendar/${clientID}/`,
      body,
      authHeader,
    );
  }

  ignorePost(authHeader, body) {
    return axios.post(`${URL}/users/client/rawignore/`, body, authHeader);
  }

  postHateCheck(authHeader) {
    return axios.post(`${URL}/users/client/hatecheck/`, {}, authHeader);
  }
}
