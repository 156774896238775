import React, { useState, useEffect } from "react";
import { Picklist, Option, DatePickerModal } from "react-rainbow-components";
import "./daterange.css";
import { setDates } from "../../redux/actions/others";
import { useDispatch } from "react-redux";
import { addDays } from "date-fns";
import { useSelector } from "react-redux";

const dateString = (date) => {
  return (
    String(date.getFullYear()) +
    "-" +
    String(date.getMonth() + 1).padStart(2, "0") +
    "-" +
    String(date.getDate()).padStart(2, "0")
  );
};

const DateRange = ({ usePickModal = true }) => {
  const originDates = useSelector((state) => state.Others.dates);
  const dispatch = useDispatch();
  const [state, setState] = useState({
    isOpen: false,
    selection: {
      name: "Custom",
      label: originDates.start + " - " + originDates.end,
    },
  });

  const changeDates = (label) => {
    const date = new Date();
    const result = { start: "", end: dateString(date), type: "" };
    result.type = label;
    switch (label) {
      case "Today":
        result.start = dateString(date);
        break;

      case "Week":
        result.start = dateString(addDays(date, -7));
        break;

      case "Month":
        result.start = dateString(addDays(date, -30));
        break;
      case "Year":
        result.start = dateString(addDays(date, -365));
        break;
      default:
        return;
    }
    dispatch(setDates(result));
    return result.start + " - " + result.end;
  };

  function handlePicklistChange(name) {
    if (name === "Custom") {
      setState({
        isOpen: true,
        selectionType: "range",
        value: null,
        title: "날짜를 선택하세요",
      });
    } else {
      setState({ selection: { label: changeDates(name) } });
    }
  }

  function formatDates(dates) {
    if (dates) {
      const startDay = dateString(dates[0]);
      if (dates.length > 1) {
        const endDay = dateString(dates[1]);
        dispatch(setDates({ start: startDay, end: endDay }));
        return `${startDay} - ${endDay}`;
      }
      return startDay;
    }
    return "";
  }

  function handleDatePickerChange(value) {
    const formatedDate = formatDates(value);
    if (value.length > 1) {
      setState({
        value,
        selection: { name: "Custom", label: formatedDate },
        title: formatedDate,
        isOpen: false,
      });
    } else {
      setState({
        value,
        selection: { name: "Custom", label: formatedDate },
        title: formatedDate,
        isOpen: true,
      });
    }
  }

  const containerStyles = {
    width: "250px",
  };
  return (
    <>
      <div className="daterange">
        {usePickModal ? (
          <>
            <div className="rainbow-flex rainbow-align_right">
              <button
                className="small-button"
                onClick={() => handlePicklistChange("Custom")}
              >
                {originDates.start + " - " + originDates.end}
              </button>
            </div>
            <DatePickerModal
              className="datepicker"
              title={state.title}
              isOpen={state.isOpen}
              variant="single"
              value={state.value}
              selectionType="range"
              onChange={handleDatePickerChange}
              onRequestClose={() => setState({ isOpen: false })}
            />
          </>
        ) : null}
      </div>
      <div className="range-select-button">
        <button
          className="small-button"
          onClick={() => handlePicklistChange("Today")}
        >
          일간
        </button>
        <button
          className="small-button"
          onClick={() => handlePicklistChange("Week")}
        >
          주간
        </button>
        <button
          className="small-button"
          onClick={() => handlePicklistChange("Month")}
        >
          월간
        </button>
      </div>
    </>
  );
};

export default DateRange;
