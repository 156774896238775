import React from "react";
import "./profile-card.css";
import { useDispatch } from "react-redux";
import { setProfile } from "../../redux/actions/others";

const ProfileCard = ({ image, name, group, id }) => {
  const dispatch = useDispatch();
  const showProfile = (data) => {
    dispatch(setProfile(data));
  };

  return (
    <div className="col-4">
      <div className="card">
        <div className="d-row">
          <div className="col-6">
            <img className="profile" src={image} alt="profile" />
          </div>

          <div className="col-6 profile-content">
            <br />
            <h3>{name}</h3>
            <p>{group ? group : null}</p>
            <br />
            <br />
            <button
              onClick={() =>
                showProfile({
                  image: image,
                  name: name,
                  group: group,
                  id: id,
                })
              }
            >
              시작하기
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileCard;
