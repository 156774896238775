import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ignorePost } from "../../redux/actions/api_user";
import { setPost, setCheckedItems } from "../../redux/actions/others";
import useApi from "../hooks/useApi";
import { siteImage } from "../site/siteImage";
import RenderWrapper from "../display/renderWrapper";
import "./table.css";

const PostTable = (props) => {
  const initDataShow =
    props.limit && props.bodyData > Number(props.limit)
      ? props.bodyData.slice(0, Number(props.limit))
      : props.bodyData;

  const [dataShow, setDataShow] = useState(initDataShow);
  const auth = useSelector((state) => state.auth);
  const [loading, ignoreResult, error3, ignoreAction] = useApi(ignorePost);

  useEffect(() => {
    setDataShow(props.bodyData.slice(0, Number(props.limit)));
  }, [props.bodyData]);

  return (
    <>
      <div className="post-table">
        <table>
          <tbody>
            {dataShow.map((item, index) => (
              <tr key={index}>
                <td>
                  <a
                    target="_blank"
                    href={item?.url ? item.url : "#"}
                    rel="noreferrer"
                  >
                    <div className="d-row">
                      <img
                        src={siteImage(item?.site)}
                        className="table-img"
                        alt="site"
                      />
                      <div className="table-content">
                        <div className="d-row">
                          <h3>{item?.title}</h3>
                        </div>

                        <div className="contents">
                          {item?.contents && item?.contents.length > 20
                            ? item?.contents.slice(0, 40) + "..."
                            : item?.contents}
                        </div>
                      </div>
                    </div>
                  </a>
                  <RenderWrapper condition={auth.user.role === "admin"}>
                    <button
                      className="small-button link"
                      onClick={() =>
                        ignoreAction({
                          site_id: item?.site_id,
                          board_id: item?.board_id,
                          post_no: item?.post_no,
                        })
                      }
                    >
                      삭제
                    </button>
                  </RenderWrapper>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {/* <button className="detail-button">상세보기</button> */}
    </>
  );
};

export default PostTable;
