import React, { useEffect, useState } from "react";

import Dummy from "../../assets/JsonData/dummy/profiles.json";

import "../user/user.css";

import useApi from "../../components/hooks/useApi";
import { useDispatch } from "react-redux";
import { setProfile, setProfiles } from "../../redux/actions/others";
import ProfileCard from "../../components/status-card/profile-card";
import { getClients } from "../../redux/actions/api_user";
import LoadFrame from "../../components/loading/LoadFrame";

const Profile = () => {
  const dispatch = useDispatch();
  const [clientsLoading, clients, error, getClientsAction] = useApi(getClients);
  const [dataToRender, setData] = useState(null);
  useEffect(() => {
    getClientsAction();
  }, []);
  useEffect(() => {
    if (clients && !error) {
      setData(clients?.data);
    } else if (error) {
      setData(Dummy);
    }
  }, [clients, error]);
  useEffect(() => {
    dispatch(setProfiles(dataToRender));
  }, [dataToRender]);

  return (
    <div style={{ paddingTop: "20px" }}>
      <LoadFrame loading={clientsLoading} error={error}>
        <div className="d-row">
          {dataToRender
          ? dataToRender.map((data) => (
              <ProfileCard
                  image={data.image}
                  name={data.name}
                  group={data.group}
                  id={data.id}
                  key={data.id}
              />
              ))
          : null}
        </div>
      </LoadFrame>
    </div>
  );
};

export default Profile;
