import {
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT_SUCCESS,
  REGISTER_FAILURE,
  CLEAR_PROFILE,
  AUTH_ERROR,
  CHECKAUTH_SUCCESS,
  CHANGEPW_SUCCESS,
  CHANGEPW_FAIL,
} from "./types";
import { ApiService } from "../../ApiService";
import { authHeader } from "../../helpers/authHeader";

const apiService = new ApiService();

export const signin = (username, password) => (dispatch) => {
  return new Promise((resolve, reject) => {
    const body = { username: username, password: password };
    apiService
      .signin(body)
      .then((res) => {
        dispatch({
          type: LOGIN_SUCCESS,
          payload: res.data,
        });
        resolve();
      })
      .catch((err) => {
        let response = err.response;
        if (response.status == 500) {
          window.alert("서버 오류 : 관리자에게 문의해주세요");
        } else if (response.status == 400) {
          window.alert(response.data.non_field_errors);
        }
        dispatch({ type: LOGIN_FAILURE });
        reject(err);
      });
  });
};

export const signout = () => (dispatch, getState) => {
  apiService
    .signout(authHeader(getState))
    .then((res) => {
      dispatch({ type: LOGOUT_SUCCESS });
      dispatch({ type: CLEAR_PROFILE });
    })
    .catch((err) => {
      dispatch({ type: AUTH_ERROR });
    });
};

export const checkAuth = () => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    apiService
      .checkAuth(authHeader(getState))
      .then((res) => {
        dispatch({
          type: CHECKAUTH_SUCCESS,
          payload: res.data,
        });
        resolve();
      })
      .catch((err) => {
        dispatch({ type: AUTH_ERROR });
        reject(err);
      });
  });
};

export const changePassword =
  (old_password, new_password) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const body = { old_password: old_password, new_password: new_password };
      apiService
        .changePassword(authHeader(getState), 2, body)
        .then((res) => {
          dispatch({
            type: CHANGEPW_SUCCESS,
            payload: res.data,
          });
          resolve();
        })
        .catch((err) => {
          dispatch({ type: CHANGEPW_FAIL });
          reject(err);
        });
    });
  };
