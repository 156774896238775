import AdminPage from "./pages/admin/admin";
import HomePage from "./pages/user/homepage";
import SigninPage from "./pages/shared/signin/SigninPage";
import { LoadElement } from "./components/loading/LoadFrame";
import React, { useState, useEffect } from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { useSelector } from "react-redux";

import ScrollToTop from "./components/ScrollToTop";
import RedirectRoutes from "./RedirectRoutes";
import useApi from "./components/hooks/useApi";

import { checkAuth } from "./redux/actions/auth";
import RouteTracker from "./ga4/RouteTracker";
import ReactGA from "react-ga4";
import { ErrorBoundary } from "react-error-boundary";
import ErrorPage from "./pages/shared/error/ErrorPage";

const routePath = {
  admin: [{ path: "/admin/*", component: AdminPage }],
  customer: [{ path: "/user/*", component: HomePage }],
  anonymous: [{ path: "/signin", component: SigninPage }],
};


const SecureRoutes = () => {
  const [role, setRole] = useState("anonymous");
  const auth = useSelector((state) => state.auth);
  const profile = useSelector((state) => state.Others.profile);

  const [loading, resolved, error, checkAuthAction] = useApi(checkAuth);

  useEffect(() => {
    if (auth?.token !== null) {
      checkAuthAction();
    }
  }, []);

  useEffect(() => {
    if (auth) {
      if (auth.user && auth.isAuthenticated) {
        setRole(auth.user.role || "anonymous");
        let uid = auth.user?.id?.toString();
        ReactGA.set({
          userId: uid ? "UID_" + uid : undefined,
        });
      } else {
        setRole("anonymous");
        ReactGA.set({
          userId: undefined,
        });
      }
    }
  }, [auth, auth.isAuthenticated]);

  useEffect(() => {
    if (profile) {
      let cid = profile.id?.toString();
      ReactGA.set({
        clientId: cid ? "CID_" + cid : undefined,
      });
    } else {
      ReactGA.set({
        clientId: undefined,
      });
    }
  }, [profile]);

  const genRoutes = (role) => {
    const routeData = routePath[role];
    if (routeData) {
      return routeData.map((data) => {
        let ComponentToRender = data["component"];
        return (
          <Route
            exact
            key={data["path"]}
            path={data["path"]}
            element={<ComponentToRender />}
          />
        );
      });
    }
    return;
  };

  return (
    <BrowserRouter>
      <ErrorBoundary FallbackComponent={ErrorPage}>
        <ScrollToTop />
        <RouteTracker userId={auth?.user?.id} />
        <Routes>
          {genRoutes(role)}
          <Route
            path="*"
            element={
              loading ? (
                <LoadElement main={false} />
              ) : localStorage.getItem("token") && !auth.isAuthenticated ? (
                <LoadElement main={false} />
              ) : (
                <RedirectRoutes role={role} />
              )
            }
          />
        </Routes>
      </ErrorBoundary>
    </BrowserRouter>
  );
};

export default SecureRoutes;
