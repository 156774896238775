import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./mail.css";
import { setPost } from "../../redux/actions/others";
import { siteImage } from "../site/siteImage";
import { localTime } from "../../function";

import Badge from "@mui/material/Badge";
import { Tooltip } from "@mui/material";

const MailTable = ({ dataShow, listState, allCheckState }) => {
  const [checkedItems, setCheckList] = listState;
  const [isAllChecked, setIsAllChecked] = allCheckState;
  const dispatch = useDispatch();
  const showPost = (index) => {
    dispatch(setPost(dataShow[index]));
  };

  const checkedItemHandler = (data, isChecked) => {
    if (isChecked) {
      checkedItems.push(data);
      setCheckList(checkedItems);
    } else {
      for (var i = 0; i < checkedItems.length; i++) {
        if (checkedItems[i][0] === data[0] && checkedItems[i][1] === data[1]) {
          checkedItems.splice(i, 1);
          break;
        }
      }
      setCheckList(checkedItems);
    }
  };

  return (
    <div className="table-main">
      <div className="table-wrapper">
        <table>
          <tbody>
            {dataShow.map((item, index) => (
              <tr key={index}>
                <td>
                  <div className="d-row first">
                    <Issue
                      num={item.id}
                      type={item.type}
                      func={checkedItemHandler}
                      all={isAllChecked}
                      checkedItems={checkedItems}
                    />
                    <Badge
                      badgeContent={item.board_dc_type === "M" ? "M" : 0}
                      color="primary"
                    >
                      <Tooltip title={item.board_description}>
                        <img
                          src={siteImage(item.site)}
                          className="logo"
                          alt="site"
                        />
                      </Tooltip>
                    </Badge>
                    <div
                      className="table-element hate-list-content"
                      onClick={() => showPost(index)}
                      onKeyDown={() => showPost(index)}
                      role="button"
                      tabIndex={0}
                    >
                      <div className="d-row second">
                        <div>
                          <span className="row-header">
                            {item.title ? (
                              <span className="row-title">{item.title}</span>
                            ) : null}
                            <span className="row-nickname">
                              {item.nickname}
                            </span>
                          </span>
                          {item.w_user_id ? (
                            <span className="material-icons-outlined">
                              how_to_reg
                            </span>
                          ) : null}
                        </div>
                        <p>
                          {item.type + "  |  " + localTime(item.written_at)}
                        </p>
                      </div>

                      <div className="row-content">{item.content}</div>
                    </div>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const Issue = ({ num, type, func, all, checkedItems }) => {
  const [bChecked, setChecked] = useState(false);
  const allCheckHandler = () => setChecked(all);
  useEffect(() => allCheckHandler(), [all]);
  useEffect(() => {
    if (checkedItems) {
      let hit = false;
      for (var i = 0; i < checkedItems.length; i++) {
        if (checkedItems[i][0] === num) {
          setChecked(true);
          hit = true;
          break;
        }
      }
      if (!hit) {
        setChecked(false);
      }
    }
  }, [checkedItems]);
  const checkHandler = ({ target }) => {
    setChecked(!bChecked);
    func([num, type], target.checked);
  };
  return (
    <>
      <input
        type="checkbox"
        checked={bChecked}
        className="check"
        onChange={(e) => checkHandler(e)}
      />
    </>
  );
};

export default MailTable;
