import React from "react";
import { useSelector } from "react-redux";
import "./topnav.css";

import { Link } from "react-router-dom";

import Dropdown from "../dropdown/Dropdown";

import user_menu from "../../assets/JsonData/user_menus.json";

import useApiSimple from "../hooks/useApiSimple";
import { signout } from "../../redux/actions/auth";

const renderUserToggle = (user) => (
  <div className="topnav__right-user">
    {user.display_name ? (
      <>
        <div className="topnav__right-user__image">
          <img src={user.image} alt="" />
        </div>
        <div className="topnav__right-user__name">{user.display_name}</div>
      </>
    ) : (
      <span className="material-icons-outlined">menu</span>
    )}
  </div>
);

const RenderUserMenu = (item, index) => {
  const [loading, signoutAction] = useApiSimple(signout);

  if (item.content === "Logout") {
    return (
      <div
        key={index}
        className="notification-item"
        onClick={signoutAction}
        onKeyDown={signoutAction}
        role="button"
        tabIndex={0}
      >
        <i className={item.icon}></i>
        <span>{item.content}</span>
      </div>
    );
  } else {
    return (
      <Link to="/" key={index}>
        <div className="notification-item">
          <i className={item.icon}></i>
          <span>{item.content}</span>
        </div>
      </Link>
    );
  }
};

const TopNav = () => {
  const auth = useSelector((state) => state.auth);

  const current_user = {
    display_name: auth.user.first_name,
    image: auth.user?.image,
  };

  return (
    <div className="topnav">
      <div className="topnav__search" />
      <div className="topnav__right">
        <div className="topnav__right-item">
          {/* dropdown here */}
          <Dropdown
            customToggle={() => renderUserToggle(current_user)}
            contentData={user_menu}
            renderItems={(item, index) => RenderUserMenu(item, index)}
          />
        </div>
      </div>
    </div>
  );
};

export default TopNav;

export const TopNav2 = ({ title }) => {
  const profile = useSelector((state) => state.Others.profile);
  return (
    <div className="topnav nav2">
      <div className="topnav__title">{title}</div>
      <div className="topnav__right">
        <div className="topnav__right-item">
          {/* dropdown here */}
          <Dropdown
            customToggle={() => renderUserToggle(profile)}
            contentData={user_menu}
            renderItems={(item, index) => RenderUserMenu(item, index)}
          />
        </div>
      </div>
    </div>
  );
};
