import React from "react";

const Wrapper = ({ children, condition }) => {
  if (condition) {
    return <>{children}</>;
  } else {
    return <></>;
  }
};

export default Wrapper;
